<!--
 * @Description: 扫码入口页面
 * @Author: yangsheng
 * @Date: 2021-11-08 15:08:07
 * @LastEditors: liutq
 * @LastEditTime: 2024-05-21 14:11:36
-->
<template>
    <div class="page">
        <van-loading v-if="loading" :size="50" type="circular" color="rgb(100, 100, 100)" />
    </div>
</template>

<script>
import { synthesis, findScanTypeInfo } from '@/assets/js/apolloGql.js'

export default {
    data() {
        return {
            loading: true,
            scanType: null
        }
    },
    created() {
        window.localStorage.removeItem('modelInfo')
        // 保存入口类型
        window.sessionStorage.setItem('entryType', 2)
        // 扫码id
        const scanId = this.$route.query.scan_id
        // 移除显示的状态
        window.localStorage.removeItem('share')
        // 设备id
        // const deviceId = this.$route.query.device_id
        window.localStorage.removeItem('createTime')
        this.requestSynthesis(scanId)

    },
    methods: {
        // 发起合成
        async requestSynthesis(scanId) {
            const result = await this.$apollo
                .mutate({
                    mutation: synthesis,
                    variables: {
                        scanId: scanId
                    }
                })
            const resultData = result.data.synthesis
            if (resultData.code === 200) {
                let modelInfo = {
                    scanId: scanId
                }
                window.localStorage.setItem('modelInfo', JSON.stringify(modelInfo))
                const scanResult = await this.$apollo
                    .query({
                        query: findScanTypeInfo,
                        fetchPolicy: 'network-only',
                        variables: {
                            scanId: scanId
                        },
                    })

                const scanResultData = scanResult.data.findScanTypeInfo
                window.localStorage.setItem('deviceType', JSON.stringify(scanResultData.data.deviceType))
                if (!scanResultData.data.biaMeasure && !scanResultData.data.staticEval && !scanResultData.data.girthMeasure) {
                    this.updateModelInfo(scanResultData.data)
                    // 直接跳转到报告页面
                    this.$router.push({
                        path: '/report'
                    })
                } else {
                    // 跳转到合成中页面
                    this.$router.push({
                        path: '/modeling'
                    })
                } 
            } else if (resultData.code === 404) {
                // 获取不到对应的扫描信息，跳转到无报告页面
                this.$router.push({
                    path: '/exception/report'
                })
            } else if (resultData.code === 406) {
                // 跳转到重复绑定页面
                this.$router.push({
                    path: '/exception/repeatBinding'
                })
            } else {
                this.$toast(resultData.message);
            }
        },
        // 更新modelInfo，缓存
        updateModelInfo(data) {
            const modelInfo = {
                scanId: data.scanId,
                memberId: data.memberId,
                biaMeasure: data.biaMeasure,
                staticEval: data.staticEval,
                createTime: data.createTime,
                shoulderEval: data.shoulderJointEval,
                biaStatus: data.bmBiaScanInfo ? data.bmBiaScanInfo.result : 4,
                bdaStatus: data.bmBdaScanInfo ? data.bmBdaScanInfo.result : 4,
                evalStatus: data.bsScanInfo ? data.bsScanInfo.result : 4,
                bsShoulderScanResult: data.bsShoulderScanInfo ? data.bsShoulderScanInfo.result : 4,
                scanTime: data.scanTime,
                deviceId: data.deviceId,
				utc: data?.utc
            }
            window.localStorage.setItem('modelInfo', JSON.stringify(modelInfo))
        }
    }
}
</script>

<style scoped lang="less">
.page {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#262849), to(#07090f));
    background-image: linear-gradient(180deg, #262849, #07090f);
}
</style>
