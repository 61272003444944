<template>
  <div :class="['report-result', $i18n.locale]">
    <div class="result-title" v-if="scoreType === 1">
      <span>{{ $t("mass.title") }}</span>
    </div>
    <div v-else class="shape-title">
      <span>{{ $t("shape.title") }}</span>
    </div>
    <div class="result-wrapper">
      <div class="conent-box" v-if="scoreType === 1 && isScore">
        <div class="con-box">
          <img src="@/assets/images/left_icon.png" alt="" />
          <div class="mass-score">
            <p class="score">{{ bodyScore.massScore }}</p>
            <span class="unit">{{ $t("mass.score") }}</span>
          </div>
          <img src="@/assets/images/right_icon.png" alt="" />
        </div>
        <div class="cont-box" v-if="bodyScore.massConScore">
          <span class="cont-box-span">{{ $t("mass.measure.comparedNet") }}</span>
          <img
            v-if="trendMassStatus === 0 && $i18n.locale === 'ar-AR'"
            src="@/assets/images/icon_just.png"
            alt=""
            style="transform: scaleX(-1)"
          />
          <img
            v-else-if="trendMassStatus === 0 && $i18n.locale !== 'ar-AR'"
            src="@/assets/images/icon_just.png"
            alt=""
          />
          <img
            v-else-if="trendMassStatus == 1"
            src="@/assets/images/icon_rise.png"
            alt=""
          />
          <img v-else src="@/assets/images/icon_decline.png" alt="" />
          <span
            :class="
              trendMassStatus == 0
                ? 'cont-box-font'
                : trendMassStatus == 1
                ? 'cont-box-font1'
                : 'cont-box-font2'
            "
            >{{ Math.abs(bodyScore.massScore - bodyScore.massConScore) }}</span
          >
        </div>
      </div>
      <div class="conent-box" v-else>
        <div v-if="isScore">
          <div class="con-box">
            <img src="@/assets/images/left_icon.png" alt="" />
            <div class="eval-score">
              <p class="score">{{ bodyScore.evalScore }}</p>
              <span class="unit">{{ $t("mass.score") }}</span>
            </div>
            <img src="@/assets/images/right_icon.png" alt="" />
          </div>
          <div class="cont-box" v-if="bodyScore.evalConScore">
            <span class="cont-box-span">{{ $t("mass.measure.comparedNet") }}</span>
            <img v-if="trendStatus == 0" src="@/assets/images/icon_just.png" alt="" />
            <img
              v-else-if="trendStatus == 1"
              src="@/assets/images/icon_rise.png"
              alt=""
            />
            <img v-else src="@/assets/images/icon_decline.png" alt="" />
            <span
              :class="
                trendStatus == 0
                  ? 'cont-box-font'
                  : trendStatus == 1
                  ? 'cont-box-font1'
                  : 'cont-box-font2'
              "
              >{{
                bodyScore.evalConScore
                  ? Math.abs(bodyScore.evalScore - bodyScore.evalConScore)
                  : 0
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="last-conclusion">
        <img src="@/assets/images/report_completion_icon.png" alt />
        <div>
          <h5>{{ $t("shoulder.conclusion") }}</h5>
          <p v-if="scoreType === 1 && $i18n.locale !== 'ar-AR'">
            {{ $t("mass.status") }}{{ bodyScore.massLevel.conclusion }}，
            {{ $t("mass.PBF") }}{{ bodyState.ptType }}， {{ $t("mass.WT")
            }}{{ bodyState.weightType }}{{ $t("mass.endSymbol") }}
          </p>
          <p v-if="scoreType === 1 && $i18n.locale === 'ar-AR'">
            {{ $t("mass.status") }}{{ bodyScore.massLevel.conclusion }},
            {{ $t("mass.PBF") }}{{ bodyState.ptType }}, {{ $t("mass.WT")
            }}{{ bodyState.weightType }}{{ $t("mass.endSymbol") }}
          </p>
          <!-- 体态成功显示体态相关结论 -->
          <p v-if="scoreType === 2" class="bs-conclusion">
            <span>{{ $t("shape.status") }} {{ bodyScore.evalLevel.conclusion }}</span>
            <template v-if="problemList.length !== 0 && $i18n.locale !== 'ar-AR'">
              <span>{{ $t("shape.possibility") }}</span>
              <span v-for="(pro, idx) in problemList" :key="'k_' + idx">{{
                idx !== problemList.length - 1 ? pro + "、" : pro
              }}</span>
              <span> {{ $t("mass.endSymbol") }}</span>
              <!-- 1.12.0版本去掉 -->
              <!-- <span>的{{ evalLevel.conclusion === '优秀' ? '概率。' : evalLevel.conclusion === '良好' ? '可能性。' : evalLevel.conclusion === '一般' ? '倾向。' : '风险。' }}</span> -->
            </template>
            <template v-else-if="problemList.length !== 0 && $i18n.locale === 'ar-AR'">
              <span>{{ $t("shape.possibility") }}</span>
              <span v-for="(pro, idx) in problemList" :key="'k_' + idx">{{
                idx !== problemList.length - 1 ? pro + "," : pro
              }}</span>
              <span> {{ $t("mass.endSymbol") }}</span>
              <!-- 1.12.0版本去掉 -->
              <!-- <span>的{{ evalLevel.conclusion === '优秀' ? '概率。' : evalLevel.conclusion === '良好' ? '可能性。' : evalLevel.conclusion === '一般' ? '倾向。' : '风险。' }}</span> -->
            </template>
            <template v-else>
              <span> {{ $t("mass.endSymbol") }}</span>
            </template>
          </p>
        </div>
      </div>
      <div class="last-suggest">
        <img src="@/assets/images/report_suggest_icon.png" alt />
        <div>
          <h5>{{ $t("shape.suggest") }}</h5>
          <p v-if="scoreType === 1">{{ bodyScore.massLevel.advice }}</p>
          <!-- 体态成功显示体态相关建议 -->
          <p v-if="scoreType === 2">{{ bodyScore.evalLevel.advice }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { findBodyScore, findBodyState, bsEvalInfo } from "@/assets/js/apolloGql.js";
import { mapState } from "vuex";
export default {
  props: {
    scoreType: Number,
    massInfo: Object,
    isScore: Number,
  },
  data() {
    return {
      modelInfo: JSON.parse(window.localStorage.getItem("modelInfo")),
      bodyScore: {
        evalLevel: { conclusion: "" },
        massLevel: { conclusion: "" },
      },
      massScore: {},
      bodyState: {},
      massLevel: { conclusion: "" },
      evalLevel: { conclusion: "" },
      bodyTypeList: ["虚弱型", "肌肉型", "肥胖型", "健康型"],
      weightTypeList: [
        this.$t("shape.models.low"),
        this.$t("shape.models.normal"),
        this.$t("shape.models.high"),
      ],
      ptTypeList: [
        this.$t("shape.models.low"),
        this.$t("shape.models.normal"),
        this.$t("shape.models.high"),
      ],
      problemList: [],
    };
  },
  computed: {
    ...mapState(["newMathTtData", "isNewMathTt"]),
    trendMassStatus() {
      let number = this.bodyScore.massScore - this.bodyScore.massConScore;
      if (number == 0) {
        return 0;
      } else if (number > 0) {
        console.log(number);
        return 1;
      } else {
        return 2;
      }
    },
    trendStatus() {
      let number = this.bodyScore.evalScore
        ? this.bodyScore.evalScore - this.bodyScore.evalConScore
        : 0;
      if (number == 0) {
        return 0;
      } else if (number > 0) {
        console.log(number);
        return 1;
      } else {
        return 2;
      }
    },
  },
  watch: {
    newMathTtData: {
      handler() {
        this.mathTtData();
      },
      deep: true,
    },
  },
  mounted() {
    // 获取身体分数
    this.findBodyScore();
	setTimeout(() =>{
		// 防止数据未完成赋值下就请求数据
		this.bsEvalInfo();
	}, 1200)
    this.findBodyState();
    // // bia成功则获取身体状态
    // if (this.modelInfo.biaStatus === 1) {
    //     this.findBodyState()
    // }
    // // 体态成功则获取体态评估数据
    // if (this.modelInfo.evalStatus === 1) {
    //     this.bsEvalInfo()
    // }
  },
  methods: {
    shareFlag() {
      const shareScanId = window.localStorage.getItem("shareScanId");
      //  分享链接进入取分享报告ScanId
      const shareFlag = !JSON.parse(localStorage.getItem("share")) && shareScanId;
      return shareFlag ? shareScanId : false;
    },

    // 获取身体分数数据
    findBodyScore() {
      const that = this;
      this.$apollo
        .query({
          query: findBodyScore,
          variables: {
            scanId: this.shareFlag() ? this.shareFlag() : this.massInfo.scanId,
            scanTime: this.modelInfo.createTime,
            scanType: this.scoreType,
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          const data = res.data.findBodyScore;
          if (data.code === 200 && data.data !== null) {
            let scoreInfo = JSON.parse(JSON.stringify(data.data));
            if (this.scoreType === 1) {
              scoreInfo.massScore = scoreInfo.massScore
                ? parseInt(scoreInfo.massScore, 10)
                : null;
              scoreInfo.massConScore = scoreInfo.massConScore
                ? parseInt(scoreInfo.massConScore, 10)
                : null;
            }
            if (this.scoreType === 2) {
              scoreInfo.evalScore = scoreInfo.evalScore
                ? parseInt(scoreInfo.evalScore, 10)
                : null;
              scoreInfo.evalConScore = scoreInfo.evalConScore
                ? parseInt(scoreInfo.evalConScore, 10)
                : null;
            }

            this.bodyScore = scoreInfo;
          }
          // 关闭加载动画
          that.$indicator.close();
        });
    },
    // 获取身体状态
    findBodyState() {
      this.$apollo
        .query({
          query: findBodyState,
          variables: {
            scanId: this.massInfo.scanId,
          },
        })
        .then((res) => {
          const data = res.data.findBodyState;
          if (data.code === 200) {
            const status = data.data;
            const bodyState = {
              weight: status.WT.v,
              rate: status.PBF.v,
              muscle: status.LM.v,
              bodyType: this.bodyTypeList[status.bodyShare - 1],
              weightType: this.weightTypeList[status.WT.status - 1],
              ptType: this.ptTypeList[status.PBF.status - 1],
            };
            this.bodyState = bodyState;
          }
        });
    },
    // 体态评估
    bsEvalInfo() {
      this.$apollo
        .query({
          query: bsEvalInfo,
          variables: {
            scanId: this.shareFlag() ? this.shareFlag() : this.massInfo.scanId,
          },
        })
        .then((res) => {
          const data = res.data.bsEvalInfo;
          if (data.code === 200 && !this.isNewMathTt) {
            this.body = data.data;
            if (data.data.headForward > 0) {
              this.problemList.push(this.$t("shape.item.head"));
            }
            if (data.data.headSlant > 0 || data.data.headSlant < 0) {
              this.problemList.push(this.$t("shape.item.headSlant"));
            }
            if (data.data.roundShoulderLeft > 15) {
              this.problemList.push(this.$t("shape.item.roundShoulderLeft"));
            }
            if (data.data.roundShoulderRight > 15) {
              this.problemList.push(this.$t("shape.item.roundShoulderRight"));
            }
            if (data.data.highLowShoudler > 0 || data.data.highLowShoudler < 0) {
              this.problemList.push(this.$t("shape.item.highLowShoudler"));
            }
            if (data.data.pelvisForward > 185) {
              this.problemList.push(this.$t("shape.exception[4].title"));
            } else if (data.data.pelvisForward < 175) {
              this.problemList.push(this.$t("shape.exception[5].title"));
            }
            if (data.data.leftKneeCheck > 190) {
              this.problemList.push(this.$t("shape.exception[6].title"));
            } else if (data.data.leftKneeCheck < 160) {
              this.problemList.push(this.$t("shape.exception[7].title"));
            }
            if (data.data.rightKneeCheck > 190) {
              this.problemList.push(this.$t("shape.exception[8].title"));
            } else if (data.data.rightKneeCheck < 160) {
              this.problemList.push(this.$t("shape.exception[9].title"));
            }
            if (data.data.leftLegXo <= 190 && data.data.leftLegXo >= 170) {
              if (data.data.rightLegXo >= 0 && data.data.rightLegXo < 170) {
                this.problemList.push(this.$t("shape.exception[10].title"));
              } else if (data.data.rightLegXo > 190 && data.data.rightLegXo <= 360) {
                this.problemList.push(this.$t("shape.exception[11].title"));
              }
            } else if (data.data.leftLegXo >= 0 && data.data.leftLegXo < 170) {
              if (data.data.rightLegXo <= 190 && data.data.rightLegXo >= 170) {
                this.problemList.push(this.$t("shape.exception[10].title"));
              } else if (data.data.rightLegXo >= 0 && data.data.rightLegXo < 170) {
                this.problemList.push(this.$t("shape.exception[12].title"));
              }
            } else if (data.data.leftLegXo > 190 && data.data.leftLegXo <= 360) {
              if (data.data.rightLegXo <= 190 && data.data.rightLegXo >= 170) {
                this.problemList.push(this.$t("shape.exception[11].title"));
              } else if (data.data.rightLegXo > 190 && data.data.rightLegXo <= 360) {
                this.problemList.push(this.$t("shape.exception[13].title"));
              }
            }
          } else {
            this.mathTtData();
          }
        });
    },
    mathTtData() {
      if (this.isNewMathTt) {
        this.problemList = [];
        if (this.newMathTtData.headForwardState > 0) {
          this.problemList.push(this.$t("shape.item.head"));
        }
        if (this.newMathTtData.headSlantState > 0) {
          this.problemList.push(this.$t("shape.item.headSlant"));
        }
        if (this.newMathTtData.leftRoundShoulderState > 0) {
          this.problemList.push(this.$t("shape.item.roundShoulderLeft"));
        }
        if (this.newMathTtData.rightRoundShoulderState > 0) {
          this.problemList.push(this.$t("shape.item.roundShoulderRight"));
        }
        if (this.newMathTtData.highLowShoudlerState > 0) {
          this.problemList.push(this.$t("shape.item.highLowShoudler"));
        }
        if (this.newMathTtData.pelvisForwardState > 0) {
          this.problemList.push(this.$t("shape.exception[5].title"));
        }

        if (this.newMathTtData.leftKneeCheckState > 0) {
          switch (this.newMathTtData.leftKneeCheckState) {
            case 1:
              this.problemList.push(this.$t("shape.exception[7].title"));
              break;
            case 2:
              this.problemList.push(this.$t("shape.exception[6].title"));
              break;
            case 3:
              this.problemList.push(this.$t("shape.exception[7].title"));
              break;
            case 4:
              this.problemList.push(this.$t("shape.exception[6].title"));
              break;
            default:
              break;
          }
        }
        if (this.newMathTtData.rightKneeCheckState > 0) {
          switch (this.newMathTtData.rightKneeCheckState) {
            case 1:
              this.problemList.push(this.$t("shape.exception[9].title"));
              break;
            case 2:
              this.problemList.push(this.$t("shape.exception[8].title"));
              break;
            case 3:
              this.problemList.push(this.$t("shape.exception[9].title"));
              break;
            case 4:
              this.problemList.push(this.$t("shape.exception[8].title"));
              break;
            default:
              break;
          }
        }
        if (this.newMathTtData.legType > 0) {
          switch (this.newMathTtData.legType) {
            case 1:
              this.problemList.push(this.$t("shape.item.abnormal"));
              break;
            case 2:
              this.problemList.push(this.$t("shape.exception[10].title"));
              break;
            case 3:
              this.problemList.push(this.$t("shape.exception[11].title"));
              break;
            case 4:
              this.problemList.push(this.$t("shape.exception[12].title"));
              break;
            case 5:
              this.problemList.push(this.$t("shape.exception[13].title"));
              break;
            default:
              this.problemList.push(
                `${this.$t("shape.item.leg")}${
                  ["zh-CN", "ja-JP", "zh-TW", "zh-HK"].indexOf(this.$i18n.locale) > -1
                    ? ""
                    : " "
                }${this.$t("shape.item.keAbnormal")}`
              );
              break;
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.report-result {
  margin: 15px 15px 0 11px;

  .result-title {
    max-width: 301px;
    width: fit-content;
    min-height: 30px;
    margin-bottom: -2px;
    line-height: 20px;
    color: #222222;
    background: url(../../assets/images/report/body_over_en.png) center no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    span {
      letter-spacing: 0.1px;
      margin-left: 10px;
      margin-right: 15px;
      font-size: 14px;
      text-align: left;
    }
  }

  .shape-title {
    width: 221px;
    height: 28px;
    margin-bottom: -2px;
    line-height: 26px;
    color: #222222;
    font-family: AppleSystemUIFont;
    background: url(../../assets/images/report/shape_over_en.png) center no-repeat;
    background-size: 100% 100%;

    span {
      letter-spacing: 0.2px;
      width: 98px;
      height: 14px;
      margin-left: -25px;
      font-size: 14px;
    }
  }

  .result-wrapper {
    width: 353px;
    background: url(../../assets/images/report/bj1.png) center no-repeat;
    background-size: 100% 100%;

    .conent-box {
      flex-direction: column;
    }

    .cont-box {
      margin-top: 7px;
      width: 353px;
      display: flex;
      align-items: center;
      justify-content: center;

      .cont-box-span {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8aa3be;
        line-height: 12px;
      }

      img {
        width: 14px;
        height: 14px;
      }

      .cont-box-font {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1b9edb;
        line-height: 12px;
        letter-spacing: 10;
      }

      .cont-box-font1 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #29d590;
        line-height: 12px;
      }

      .cont-box-font2 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #db942c;
        line-height: 12px;
      }
    }

    .con-box {
      width: 353px;
      height: 36px;
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      // margin-top: 7px;
      // flex-direction: column;
      img {
        width: 44px;
        height: 4px;
      }

      .mass-score {
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding: 0 31px;
      }

      .eval-score {
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding: 0 31px;
      }

      p {
        font-size: 36px;
        background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .unit {
        margin-left: 2px;
        font-size: 12px;
        color: #8aa3be !important;
      }
    }

    .last-conclusion {
      margin: 0 14px;
    }

    .last-suggest {
      margin: 0 14px;
      padding-bottom: 20px;
    }

    > div {
      display: flex;
      align-items: flex-start;
      padding-bottom: 10px;

      img {
        display: block;
        width: 19px;
        // 禁止部分安卓机下图片自动放大
        pointer-events: none;
      }

      > div {
        color: #fff;
        font-size: 14px;
        padding-left: 9px;

        h5 {
          font-size: 14px;
          font-weight: normal;
          padding-bottom: 2px;
          text-align: left;
        }

        p {
          color: #8aa3be;
          line-height: 1.29;
          text-align: left;

          &.bs-conclusion {
            font-size: 0;

            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
&.pt-PTO {
  .result-title {
    span {
      font-size: 12px;
    }
  }
}

&.ja-JP,
&.zh-CN {
  .result-title {
    font-family: AppleSystemUIFont;
    font-weight: 550;
    line-height: 26px;
  }

  .shape-title {
    width: 140px;

    span {
      font-weight: 550;
    }
  }
}

&.es-ES,
&.pt-PT,
&.tr-TR,
&.de-DE,
&.el-GR {
  .shape-title {
    width: 300px;

    span {
      font-weight: 550;
    }
  }
}

&.fr-FR {
  .shape-title {
    width: 320px;
    span {
      font-size: 13px;
      font-weight: 550;
    }
  }
}
&.pt-PTO {
  .shape-title {
    span {
      margin-left: -15px;
      font-size: 13px;
    }
  }
}
&.ar-AR {
  .result-wrapper {
    h5 {
      text-align: right !important;
      margin-right: 20px !important;
    }
    p {
      text-align: right !important;
      margin-right: 20px !important;
    }
  }
  .shape-title {
    transform: scaleX(-1);
    span {
      display: inline-block;
      width: 200px;
      transform: scaleX(-1);
    }
  }
  .result-title {
    transform: scaleX(-1);
    span {
      transform: scaleX(-1);
    }
  }
  .con-box {
    img {
      transform: scaleX(-1);
    }
  }
  .unit {
    margin-right: 10px;
  }
}
</style>
