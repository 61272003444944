<template>
    <!-- 体态模型 -->
    <div :class="['measure-model', $i18n.locale]">
        <model-loading v-show="isLoad" class="model-loading"></model-loading>
        <div class="model-container" v-if="isNewMathTt">
            <!-- @changeShapeIndex="changeShapeIndex" -->
            <iframe-template-new ref="model" :options="iframeOptions" @isIframeLoad="findModelFile" @isModelLoad="loadModelOver"
                 @isModelLoadError="onModelLoadError"></iframe-template-new>
            <img class="iframe-bottom" src="@/assets/images/girth/transform@2x.png" alt />
        </div>
        <div class="model-container" v-else>
            <iframe-template ref="model" :options="iframeOptions" @isIframeLoad="findModelFile" @isModelLoad="loadModelOver"
                 @isModelLoadError="onModelLoadError"></iframe-template>
            <img class="iframe-bottom" src="@/assets/images/girth/transform@2x.png" alt />
        </div>
    </div>
</template>
<script>
import IframeTemplate from '@/components/model/IframeTemplate'
import IframeTemplateNew from '@/components/model/IframeTemplateNew'
import ModelLoading from '@/components/model/Loading'
import { findBsScanFileInfos } from '@/assets/js/apolloGql.js'
import { mapState } from "vuex"
export default {
    components: {
        IframeTemplate,
        ModelLoading,
        IframeTemplateNew
    },
    props: {
        errorOptions: Object
    },
    data() {
        return {
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            iframeOptions: {
                url: 'shape/index.html',
                modelPath: '',
                pointPath: '',
                width: '100%',
                height: '100% !important',
                showShapeMode: true,
                failed: false,
                errorInfo: this.errorOptions
            },
            isLoad: true,
            rotateIndex: 1
        }
    },
    computed: {
        ...mapState(["historyScanId", "isNewMathTt"])
    },
    methods: {
        findModelFile() {
            // 体态
            if (this.modelInfo.evalStatus > 0) {
                this.findBsScanFileInfos()
            } else {
                this.loadFailed()
            }
        },
        // 获取模型数据
        // 体态
        findBsScanFileInfos() {
            let scanId = ''
            let shareScanId = window.localStorage.getItem("shareScanId")
            //  分享链接进入取分享报告ScanId
            let shareFlag = !JSON.parse(localStorage.getItem('share')) && shareScanId
            if(shareFlag){
                scanId = shareScanId
            } else if (this.historyScanId) {
                scanId = this.historyScanId
            } else {
                scanId = this.modelInfo.scanId
            }
            this.$apollo
                .query({
                    query: findBsScanFileInfos,
                    variables: {
                        scanId: scanId
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    const data = res.data.findBsScanFileInfos
                    if (data && data.code === 200) {
                        this.iframeOptions.failed = false
                        // eslint-disable-next-line no-unused-vars
                        this.$lodash.each(data.data, (model, index) => {
                            if (model.fileType.id === 3) {
                                this.iframeOptions.modelPath = model.filePath
                                // this.iframeOptions.modelPath = '../../../static/model-iframe/model/bodyEval.obj'
                            }

                            if (model.fileType.id === 4) {
                                this.iframeOptions.pointPath = model.filePath
                                // this.iframeOptions.pointPath = '../../../static/model-iframe/model/bodyEval.json'
                            }
                        })

                        if (this.iframeOptions.modelPath && this.iframeOptions.pointPath) {
                            setTimeout(() => {
                                this.$refs.model.loadShapeModel(true)
                            }, 3000)
                        } else {
                            this.loadFailed()
                        }
                    } else {
                        this.loadFailed()
                    }
                })
        },
        onModelLoadError(errObj) {
            if (errObj.type === 'shape') {
                this.loadFailed()
            }
        },
        loadFailed() {
            this.isLoad = false
            this.iframeOptions.failed = true
        },
        loadModelOver() {
            this.isLoad = false
        },
        // changeShapeIndex(index) {
        //     console.log(index, '二级')
        //     this.$emit('modelRotate', index)
        // },
        turnModel(direction) {
            this.$refs.model.turnModel(direction)
        }
    }
}
</script>
<style lang="less" scoped>
.model-loading {
    width: 98%;
    height: 112%;
    left: 1%;
    top: 1%;
}

.model-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    flex-direction: column;
    position: relative;
}

&.en-US,
&.ja-JP,
&.zh-CN,
&.zh-TW,
&.zh-HK,
&.it-IT,
&.fr-FR,
&.ru-RU,
&.es-ES,
&.pt-PT,
&.id-ID,
&.tr-TR,
&.de-DE,
&.el-GR {
    .model-loading {
        height: 98%;
    }
}

.iframe-bottom {
    left: auto;
    right: auto;
    position: absolute;
    bottom: 0px;
    width: 135px;
    height: 57px;
    z-index: 0;
}
</style>
