<template>
    <div :class="['model-generating', $i18n.locale]">
        <div :class="{ 'generation-waiting-100': loadingOver }" class="generation-waiting">
            <div class="three-dimensional-bg"></div>
            <!-- 数据读取百分比 -->
            <div :class="{ 'bdaFalse': bdaFalse }">
                <p class="data-title" v-if="loadingOver">{{ $t('report.reportSuccess') }}</p>
                <p class="data-title" v-else>{{ $t('model.loading') }}</p>
                <p class="data-title">
                    <span style="font-size: 25px">
                        <count-up :decimals="1" :duration="countDuration" :endVal="endVal" :startVal="startVal"
                            @ready="onReady" />
                    </span>%
                </p>
            </div>
            <!-- 人体模型 -->
            <div class="body-model">
                <span :class="loadingOver && !bdaFalse ? 'body-box' : ''">
                    <img src="@/assets/images/failed_person.png" v-if="!bdaFalse" />
                    <report-model class="generating-model" :open-overlay="false" v-if="loadingOver"
                        type="modeling"></report-model>
                </span>
            </div>
            <!-- 与进步同步的遮挡层 -->
            <div class="speed-shadow" v-if="!loadingOver">
                <div class="speed-box" ref="shadow">
                    <div class="speed-padd"></div>
                </div>
            </div>
        </div>
        <!-- 提示信息的转换 -->
        <div class="hint-info" v-if="loadingOver">
            <!-- 提示信息 -->
            <div class="hint-msg">
                <p class="msg-title">{{ $t('model.reminder[0]') }}</p>
                <p class="msg-success">{{ tipsTitles }}</p>
            </div>
            <!-- 提示信息同步按钮 -->
            <div style="margin-top: 20px">
                <p v-if="loadingOver">
                    <span @click="successClick" class="entry-report-but">{{ reportName }}</span>
                </p>
            </div>
        </div>
        <div class="hint-info" v-else>
            <van-swipe class="loading-tips" :autoplay="6000" :show-indicators="false">
                <van-swipe-item>
                    <p class="msg-title">{{ $t('model.tabPane[0].title') }}</p>
                    <p class="msg-cont">{{ $t('model.tabPane[0].msg') }}</p>
                </van-swipe-item>
                <van-swipe-item>
                    <p class="msg-title">{{ $t('model.tabPane[1].title') }}</p>
                    <p class="msg-cont">{{ $t('model.tabPane[1].msg') }}</p>
                </van-swipe-item>
                <van-swipe-item>
                    <p class="msg-title">{{ $t('model.tabPane[2].title') }}</p>
                    <p class="msg-cont">{{ $t('model.tabPane[2].msg') }}</p>
                </van-swipe-item>
                <van-swipe-item>
                    <p class="msg-title">{{ $t('model.tabPane[3].title') }}</p>
                    <p class="msg-cont">{{ $t('model.tabPane[3].msg') }}</p>
                </van-swipe-item>
            </van-swipe>
        </div> 
        <van-overlay :show="show" @click="overlayClick">
            <div class="wrapper">
                <div class="block">
                    <img src="@/assets/images/model-generate/star.png" />
                    <div class="star-line">
                        <img class="img" src="@/assets/images/model-generate/start-line3.svg" />
                    </div>
                    <p class="star-line-p">{{ $t('model.collect[0]') }}{{ $t('model.collect[1]') }}</p>

                    <p class="confim">{{ $t('model.know') }}</p>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script type="text/ecmascript-6">
import {
    findScanTypeInfo,
    findScanTrueHistory,
    updateScanTypeInfoRowByScanId
} from '@/assets/js/apolloGql.js'
import ReportModel from '@/components/model/Report'
import countUp from 'vue-countup-v2' // 计数过渡组件

export default {
    components: {
        ReportModel,
        countUp
    },
    data() {
        return {
            startVal: 0,
            endVal: 0,
            countDuration: 10,
            // 模型加载动画
            loadingOver: false,
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            reportName: this.$t('model.btn.viewReport'),
            tipsTitles: this.$t('model.reminder[2]'), // 温馨提示
            bdaFalse: false,
            evalStatus: '',
            biaStatus: '',
            bdaStatus: '',
            findHistory: null, // 历史记录信息
            scanTypeInfo: '', // 请求返回信息
            // 合成中集合
            loadResults: [-1, -10],
            // 失败集合
            errorResults: [0, 2],
            // 是否查询历史记录
            isFindHistory: false,
            show: false,
            deviceType: 2,
        }
    },
    created() {
        this.findScanTypeInfo()
        // this.findScanTrueHistory()
    },
    mounted() {
        if (window.localStorage.getItem('newUser')) this.show = true
    },
    methods: {
        overlayClick() {
            this.show = false
            if (window.localStorage.getItem('newUser')) window.localStorage.removeItem('newUser')
            console.log('点击了', this.show)
        },
        // 设置报告为已查看
        updateViewStatus() {
            this.$apollo
                .mutate({
                    mutation: updateScanTypeInfoRowByScanId,
                    variables: {
                        scanId: this.scanTypeInfo.scanId,
                        viewStatus: 1
                    }
                })
            // .then(res => {})
        },
        // 查询合成状态
        async findScanTypeInfo() {
            const result = await this.$apollo
                .query({
                    query: findScanTypeInfo,
                    fetchPolicy: 'network-only',
                    variables: {
                        scanId: this.modelInfo.scanId
                    },
                })

            const resData = result.data.findScanTypeInfo

            if (resData.code === 200) {
                const data = resData.data
                this.scanTypeInfo = data
                clearTimeout(this.timer)
                // 更新设备类型
                window.localStorage.setItem('deviceType', JSON.stringify(data.deviceType))
                this.deviceType = data.deviceType
                /**
                 * 判断合成结果
                 * -10： 默认状态当合成中处理
                 * -1：  合成未返回
                 * 0：   失败
                 * 1：   成功
                 * 2：   超时
                 * staticEval-bsScanInfo    体态
                 * bmBiaScanInfo    体成分BIA
                 * bmBdaScanInfo    体成分BDA
                 * 测量项目(1 测量 0 未测量)与模块状态的关系
                 * 身体成分(biaMeasure)-bmBiaScanInfo
                 * 体态评估(staticEval)-bsScanInfo
                 *                     bmBdaScanInfo 存在未测量的情况
                 * 肩部评估(shoulderJointEval)-bsShoulderScanInfo
                 */
                this.endVal = this.getTotalPercent(data)
                this.updateModelInfo({
                    biaStatus: data.bmBiaScanInfo ? data.bmBiaScanInfo.result : 4,
                    bdaStatus: data.bmBdaScanInfo ? data.bmBdaScanInfo.result : 4,
                    evalStatus: data.bsScanInfo ? data.bsScanInfo.result : 4,
                    bsShoulderScanResult: data.bsShoulderScanInfo ? data.bsShoulderScanInfo.result : 4,
                    girthMeasure: data.girthMeasure ? data.girthMeasure : 0,
                    deviceId: data.deviceId,
                    scanTime: data.scanTime,
					utc: data?.utc
                })
                // // 更新设备类型
                // window.localStorage.setItem('deviceType', JSON.stringify(data.deviceType))
                // this.deviceType = data.deviceType
                if (data.biaMeasure === 1 && data.staticEval === 0) {
                    this.bdaStatus = data.bmBdaScanInfo ? data.bmBdaScanInfo.result : 4
                    // 体测
                    // bia 生成中 或 bda 生成中
                    if (this.loadResults.indexOf(data.bmBiaScanInfo.result) > -1) {
                        this.timer = setTimeout(() => {
                            this.findScanTypeInfo()
                        }, 15000)
                    }else if(data.girthMeasure === 1 && this.loadResults.indexOf(this.bdaStatus) > -1 ){
                        this.timer = setTimeout(() => {
                            this.findScanTypeInfo()
                        }, 15000)
                    } else if (data.bmBiaScanInfo.result === 1) {
                        // bia 成功
                        this.tipsTitles = this.$t('model.reminder[2]')
                    } else if (this.errorResults.indexOf(data.bmBiaScanInfo.result) > -1) {
                        // bia 失败
                        // 判断是否测量了肩部评估并且成功
                        if (data.shoulderJointEval === 1 && data.bsShoulderScanInfo.result === 1) {
                            this.tipsTitles = '您的体成分报告未成功生成，请查看肩部功能评估报告'
                        } else {
                            // 找历史数据
                            this.findScanTrueHistory()
                        }
                    }
                    this.biaStatus = data.bmBiaScanInfo.result
                } else if (data.biaMeasure === 0 && data.staticEval === 1) {
                    // 体态
                    this.evalStatus = data.bsScanInfo.result
                    this.bdaStatus = data.bmBdaScanInfo ? data.bmBdaScanInfo.result : 4
                    // 合成中 等待合成中
                    if (
                        this.loadResults.indexOf(this.evalStatus) > -1 ||
                        this.loadResults.indexOf(this.bdaStatus) > -1
                    ) {
                        this.timer = setTimeout(() => {
                            this.findScanTypeInfo()
                        }, 15000)
                    }else if(data.girthMeasure === 1 && this.loadResults.indexOf(this.bdaStatus) > -1 ){
                        this.timer = setTimeout(() => {
                            this.findScanTypeInfo()
                        }, 15000)
                    } else if (this.evalStatus === 1) {
                        // 成功
                        this.tipsTitles = this.$t('model.reminder[2]')
                    } else {
                        // 失败 超时
                        // 判断是否测量了肩部评估并且成功
                        if (data.shoulderJointEval === 1 && data.bsShoulderScanInfo.result === 1) {
                            this.tipsTitles = '您的体态报告未生成，请查看肩部功能评估报告'
                        } else {
                            // 找历史数据
                            this.findScanTrueHistory()
                        }
                    }

                } else if (data.biaMeasure === 1 && data.staticEval === 1) {
                    // 体测+体态
                    this.bothScanTypeInfo()
                } else if(data.girthMeasure === 1) {
                    console.log(`girthMeasure------${data.girthMeasure}---${this.scanTypeInfo.bmBdaScanInfo.result}`) 
                    this.bdaStatus = data.bmBdaScanInfo ? data.bmBdaScanInfo.result : 4
                    if (
                        this.loadResults.indexOf(this.bdaStatus) > -1
                    ) {
                        this.timer = setTimeout(() => {
                            this.findScanTypeInfo()
                        }, 15000)
                    }else if (this.bdaStatus === 1) {
                        // 成功
                        this.tipsTitles = this.$t('model.reminder[2]')
                    } else {
                        // 失败 超时
                        // 判断是否测量了肩部评估并且成功
                        if (data.shoulderJointEval === 1 && data.bsShoulderScanInfo.result === 1) {
                            this.tipsTitles = '您的体态报告未生成，请查看肩部功能评估报告'
                        } else {
                            // 找历史数据
                            this.findScanTrueHistory()
                        }
                    }
                } 
            } else if (resData.code === 404) {
                // 该手机号在当前公众号下没有报告
                this.$router.push({
                    path: '/exception/report'
                })
            }
        },
        // 体测 + 体态逻辑
        bothScanTypeInfo() {
            this.biaStatus = this.scanTypeInfo.bmBiaScanInfo.result
            this.bdaStatus = this.scanTypeInfo.bmBdaScanInfo ? this.scanTypeInfo.bmBdaScanInfo.result : 4
            this.evalStatus = this.scanTypeInfo.bsScanInfo.result
            // 三个中一个在加载中
            if (
                this.loadResults.indexOf(this.biaStatus) > -1 ||
                this.loadResults.indexOf(this.bdaStatus) > -1 ||
                this.loadResults.indexOf(this.evalStatus) > -1
            ) {
                this.timer = setTimeout(() => {
                    this.findScanTypeInfo()
                }, 15000)
            } else if (this.evalStatus === 1) {
                // 体态成功
                if (this.errorResults.indexOf(this.biaStatus) > -1) {
                    // bia 失败
                    this.reportName = this.$t('model.btn.postureReport')
                    this.tipsTitles = this.$t('model.reminder[4]')
                } else {
                    // bia成功
                    this.tipsTitles = this.$t('model.reminder[2]')
                }
            } else {
                // 体态失败
                if (this.errorResults.indexOf(this.biaStatus) > -1) {
                    // bia bda 失败
                    // 判断是否测量了颈部评估并且成功
                    if (
                        this.scanTypeInfo.shoulderJointEval === 1 &&
                        this.scanTypeInfo.bsShoulderScanInfo.result === 1
                    ) {
                        this.tipsTitles = '您的体成分和体态报告未生成，请查看肩部功能评估报告'
                    } else {
                        // 找历史数据
                        this.findScanTrueHistory()
                    }
                } else {
                    // bia成功 + bda成功/失败
                    this.reportName = this.$t('model.btn.bodyReport')
                    this.tipsTitles = this.$t('model.reminder[3]')
                }
            }
        },
        // 两者都失败判断历史记录
        findScanTrueHistory() {
            this.$apollo
                .query({
                    query: findScanTrueHistory,
                    variables: {
                        deviceType: this.deviceType
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    let history = res.data.findScanTrueHistory.data
                    this.findHistory = res.data.findScanTrueHistory.data
                    // history = null
                    this.isFindHistory = true
                    // this.bdaFalse = true
                    // 首次测量情况

                    // 单独体测测量失败的时候
                    this.reportName = this.$t('model.btn.lastReport')
                    if (history === null) {
                        if (this.scanTypeInfo.biaMeasure === 1 && this.scanTypeInfo.staticEval === 0) {
                            this.tipsTitles = this.$t('model.reminder[6]')
                        } else if (this.scanTypeInfo.biaMeasure === 0 && this.scanTypeInfo.staticEval === 1) {
                            this.tipsTitles = this.$t('model.reminder[7]')
                            // 两者测量都失败
                        } else if (this.scanTypeInfo.biaMeasure === 1 && this.scanTypeInfo.staticEval === 1) {
                            this.tipsTitles = this.$t('model.reminder[5]')
                        }
                        return
                    }
                    if (this.scanTypeInfo.biaMeasure === 1 && this.scanTypeInfo.staticEval === 0) {
                        this.tipsTitles = this.$t('model.reminder[6]')
                    } else if (this.scanTypeInfo.biaMeasure === 0 && this.scanTypeInfo.staticEval === 1) {
                        this.tipsTitles = this.$t('model.reminder[7]')
                        // 两者测量都失败
                    } else if (this.scanTypeInfo.biaMeasure === 1 && this.scanTypeInfo.staticEval === 1) {
                        this.countDuration = 3
                        this.tipsTitles = this.$t('model.reminder[5]')
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 成功点击跳转
        successClick() {
            if (this.isFindHistory) {
                const scanResult = {
                    scanId: this.findHistory.scanId,
                    biaStatus: this.findHistory.biaStatus,
                    bdaStatus: this.findHistory.bdaStatus,
                    evalStatus: this.findHistory.evalStatus,
                    bsShoulderScanResult: this.findHistory.bsShoulderStatus,
                    girthMeasure: this.findHistory.bsShoulderStatus,
                    shoulderJointEval: this.findHistory.shoulderJointEval,
                    deviceType: this.findHistory.deviceType,
                    scanTime: this.findHistory.scanTime,
					utc: this.findHistory.utc,
                }
                this.updateModelInfo(scanResult, true)
            }
            // 只测 肩部直接跳转到首页
            this.$router.push({
                path: '/report'
            })
        },
        // 进度渐变完成
        // eslint-disable-next-line no-unused-vars
        onReady(instance, CountUp) {
            // 合成完成
            if (this.endVal === 100) {
                // 模型合成成功
                // 该条报告设置为已查看
                this.updateViewStatus()

                // 关闭收藏弹窗提醒
                this.show = false
                // 正在合成中的时候 等待加载100%跳转页面
                if (
                    this.loadResults.indexOf(this.biaStatus) > -1 ||
                    this.loadResults.indexOf(this.bdaStatus) > -1 ||
                    this.loadResults.indexOf(this.evalStatus) > -1
                ) {
                    this.bdaFalse = false
                } else {
                    this.$refs.shadow.style.display = 'none'
                    this.loadingOver = true
                    this.bdaFalse = this.isFindHistory
                    // 如果体测失败 + 体态失败
                    // if (this.findScanType === 3) {
                    //     if (this.errorResults.indexOf(this.bmBiaResult) > -1
                    //         && this.errorResults.indexOf(this.bsResult) > -1) {
                    //         this.bdaFalse = true
                    //     }
                    // }
                    // this.bdaFalse = true
                }
            }
        },
        // 获取合成总进度
        getTotalPercent(data) {
            let { bmBiaScanInfo, bmBdaScanInfo, bsScanInfo } = data
            let totalPercent = 0
            let bmBiaPercent = 0
            let bmBdaPercent = 0
            let bsPercent = 0
            // 进度总和
            let allPercent = 0
            // 非体测
            if (data.biaMeasure === 1) {
                // -1 -10 计算进度，其余都为100
                if (bmBiaScanInfo && this.loadResults.indexOf(bmBiaScanInfo.result) > -1) {
                    bmBiaPercent = bmBiaScanInfo.percent >= 100 ? 99 : bmBiaScanInfo.percent
                } else {
                    bmBiaPercent = 100
                }
                if (data.girthMeasure === 1 && bmBdaScanInfo && this.loadResults.indexOf(bmBdaScanInfo.result) > -1) {
                    bmBdaPercent = bmBdaScanInfo.percent >= 100 ? 99 : bmBdaScanInfo.percent
                }
            }

            // 非体态
            if (data.staticEval === 1) {
                if (bsScanInfo && this.loadResults.indexOf(bsScanInfo.result) > -1) {
                    bsPercent = bsScanInfo.percent >= 100 ? 99 : bsScanInfo.percent
                } else {
                    bsPercent = 100
                }
                if (bmBdaScanInfo && this.loadResults.indexOf(bmBdaScanInfo.result) > -1) {
                    bmBdaPercent = bmBdaScanInfo.percent >= 100 ? 99 : bmBdaScanInfo.percent
                } else {
                    bmBdaPercent = 100
                }
            }

            // 单独体维
            if (data.biaMeasure === 0 && data.staticEval === 0 && data.girthMeasure === 1) {
                if (bmBdaScanInfo && this.loadResults.indexOf(bmBdaScanInfo.result) > -1) {
                    bmBdaPercent = bmBdaScanInfo.percent >= 100 ? 99 : bmBdaScanInfo.percent
                } else {
                    bmBdaPercent = 100
                }
            }

            allPercent = bmBiaPercent + bsPercent * 0.5 + bmBdaPercent * 0.5
            // 向下取整
            if (data.biaMeasure === 1 && data.staticEval === 1) {
                // 体测+体态
                totalPercent = parseFloat((allPercent / 2).toFixed(1))
            }else if(data.biaMeasure === 0 && data.staticEval === 0 && data.girthMeasure === 1) {
               // 单独体维
               totalPercent = bmBdaPercent
            }else if(data.biaMeasure === 1 && data.staticEval === 0 && data.girthMeasure === 1) {
                // 针对m30 情况下进度条卡死现象进行代码逻辑优化
                if (data.girthMeasure === 1 && bmBdaScanInfo && this.deviceType === 3 && bmBdaScanInfo.result === 1 && !bmBdaPercent) {
                    bmBdaPercent = 100
                }
               // 体维+体成分
               totalPercent = parseFloat(((bmBiaPercent + bmBdaPercent) / 2).toFixed(1))
            } else {
                // 体测或体态
                totalPercent = parseFloat(allPercent.toFixed(1))
            }

            return totalPercent
        },
        // 更新modelInfo，缓存
        updateModelInfo(data, isHistory = false) {
            const scanInfo = isHistory ? this.findHistory : this.scanTypeInfo
            const baseInfo = {
                scanId: scanInfo.scanId,
                memberId: scanInfo.memberId,
                biaMeasure: scanInfo.biaMeasure,
                staticEval: scanInfo.staticEval,
                createTime: scanInfo.createTime,
                shoulderEval: scanInfo.shoulderJointEval,
                girthMeasure: scanInfo.girthMeasure || 0,
                deviceId: scanInfo.deviceId,
                scanTime: scanInfo.scanTime,
				utc: scanInfo?.utc,
            }
            let modelInfo = Object.assign(baseInfo, data)
            window.localStorage.setItem('modelInfo', JSON.stringify(modelInfo))
        }
    }
}
</script>
<style scoped lang="less">
.generating-model {
    height: 100%;
}

.bdaFalse {
    visibility: hidden;
}

.model-generating {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #262849, #07090f);
    font-size: 14px;
    color: #fff;

    .generation-waiting {
        position: relative;
        width: 100%;
        height: 385px;
        padding-top: 30px;
        z-index: 0;

        .three-dimensional-bg {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 76px;
            background: url(../../assets/images/sidebar/model/ground_bg.png) no-repeat;
            background-size: cover;
            z-index: 1;
        }
    }

    .generation-waiting-100 {
        height: auto !important;
        padding-bottom: 10px;
    }
}

.body-box {
    display: inline-block;
    height: 265px;
    background: url(../../assets/images/sidebar/model/moxing_bg.png) no-repeat center bottom;
    background-size: 125px 50px;
    width: 100%;

    img {
        display: none !important;
    }
}

.body-model {
    position: relative;
    z-index: 10;
    margin: 0 12px;

    img {
        width: 112px;
        margin-top: 10px;
        // 禁止部分安卓机下图片自动放大
        pointer-events: none;
    }
}

.speed-shadow {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 99;
    width: 100%;
    height: 290px;
    overflow: hidden;

    .speed-box {
        position: absolute;
        top: 0;
        animation: go 12s linear normal infinite;
        z-index: 100;
        width: 100%;
        height: 200px;

        // border-top: 1px solid rgba(0, 227, 201, .4);
        .speed-padd {
            position: relative;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(180deg, rgb(0, 227, 201), rgba(0, 0, 0, 0.1));
            opacity: 0.09;
            z-index: 111;
        }
    }
}

.hint-info {
    padding-top: 25px;
    text-align: center;
}

.entry-report-but {
    display: inline-block;
    width: 200px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 6px;
    background-image: linear-gradient(to right, #00e3c9, #00a4e6);
    margin-top: 20px;
    color: #222222;
}

.hint-msg {
    padding: 0 48px;
}

.msg-title {
    font-size: 14px;
    padding-bottom: 10px;
}

.msg-cont {
    font-size: 12px;
    line-height: 1.5;
    height: 50px;
}

.msg-success {
    font-size: 12px;
    line-height: 1.5;
}

.data-title {
    padding-bottom: 10px;
}

@keyframes go {
    0% {
        top: 300px;
    }

    100% {
        top: -300px;
    }
}

.wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;
}

.block {
    width: 200px;
    min-height: 150px;
    margin-top: 42px;
    margin-right: 18px;
    // background-color: #fff;
    display: flex;
    flex-direction: column;

    img {
        width: 24px;
        margin-right: 8px;
        align-self: flex-end;
    }

    .star-line {
        position: relative;
        right: -34px;
        min-width: 150px;

        .img {
            width: 60px;
            left: 0;
            top: 0;
        }
    }
    .star-line-p{
        font-size: 12px;
        text-align: left;
    }

    .confim {
        margin: 0;
        margin-top: 16px;
        color: #00eaff;
    }

}

&.zh-CN {
    .entry-report-but {
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
    }
}

&.ja-JP,
&.es-ES,
&.it-IT,
&.en-US,
&.fr-FR,
&.pt-PT
&.ru-RU {
    .block {
        position: relative;
        width: 175px;
        height: 160px;
        margin-top: 30px;
        margin-right: 35px;
        display: flex;
        flex-direction: column;

        img {
            width: 24px;
            margin-right: -4px;
            align-self: flex-end;
        }

        .confim {
            margin: 0;
            margin-top: 16px;
            color: #00eaff;
        }
    }
}



&.en-US,
&.it-IT,
&.fr-FR,
&.ru-RU,
&.es-ES,
&.pt-PT,
&.tr-TR,
&.de-DE{
    .entry-report-but {
        width: 262px;
        color: #222222;
    }
}</style>
<style lang="less">.loading-tips {
    position: relative;
    height: 130px;

    .van-swipe-item {
        padding: 0 32px;
    }

    // .mint-swipe-indicators {
    //     bottom: 0px;
    //     .mint-swipe-indicator {
    //         width: 6px;
    //         height: 6px;
    //         border-radius: 50%;
    //         background: rgba(225, 225, 225, 0.2);
    //         opacity: 1;
    //         &.is-active {
    //             background-image: linear-gradient(to bottom, #00e3c9, #00a4e6);
    //         }
    //     }
    // }
}</style>
