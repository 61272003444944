/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 13:58:43
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-12-25 16:04:34
 */
import elLocale from 'element-ui/lib/locale/lang/el.js'
import agrEn from './agr-en'
export default {
    // 登录页面
    login: {
        loginAndRegister: 'Εγγραφή / Σύνδεση',
        registerAndLogin: 'Σύνδεση/Εγγραφή',
        userLogin: 'Σύνδεση',
        title: 'Οι λογαριασμοί κινητού και email είναι ξεχωριστοί. Σημειώστε τον τύπο σας.',
        mobileLogin: 'Τηλέφωνο',
        emailLogin: 'Email',
        email: 'E-mail',
        loginBtn: 'Σύνδεση',
        autoLogin: 'Να με θυμάσαι',
        retransmission: 's',
        formList: [
            {
                label: 'Αριθμός τηλεφώνου',
                placeholder: 'Συνέχεια',
            },
            {
                label: 'Κωδικός',
                placeholder: 'Κωδικός επαλήθευσης',
            },
            {
                label: 'Παρακαλώ εισάγετε το email σας',
            },
        ],
        btn: {
            submit: 'Δημιουργία λογαριασμού',
            title: 'Για να έχετε ακριβή δεδομένα, είναι σημαντικό να εισάγετε σωστά το ύψος, το φύλο και την ηλικία σας.',
        },
        rule: {
            phone: {
                requiredMsg: 'Μη έγκυρη μορφή αριθμού τηλεφώνου',
                patternMsg: 'Ο αριθμός τηλεφώνου δεν είναι εγγεγραμμένος, δημιουργήστε τον λογαριασμό σας',
            },
            authCode: {
                requiredMsg: 'Σφάλμα κωδικού επαλήθευσης. Παρακαλώ εισάγετε ξανά.',
            },
            email: {
                requiredMsg: 'Λάθος μορφή email',
                patternMsg: 'Το email δεν είναι εγγεγραμμένο, Δημιουργήστε λογαριασμό',
            },
        },
        codeSuccess: 'Ο κωδικός επαλήθευσης έχει σταλεί, παρακαλούμε ελέγξτε.',
        success: 'Η αποστολή ολοκληρώθηκε',
        error: 'Αποτυχία αποστολής',
        loginSuccess: 'Επιτυχής σύνδεση',
    },
    // 注册页面
    register: {
        mobileRegister: 'Τηλέφωνο',
        emailRegister: 'Email',
        enroll: 'Ο αριθμός τηλεφώνου είναι ήδη εγγεγραμμένος',
        enrollEmail: 'Email',
        search: 'Αναζήτηση',
        title: 'Οι λογαριασμοί κινητού τηλεφώνου και email είναι ανεξάρτητοι;παρακαλώ σημειώστε τον τύπο του λογαριασμού σας.',
        needRead: {
            agree: 'Συμφωνώ',
            privacy: '(Πολιτική Απορρήτου)',
        },
        register: 'Είσοδος',
        check: 'Ελέγξτε "Απορρήτο Χρήστη"',
        newUser: 'Είσοδος',
        form: {
            labelList: ['Όνομα', '* Φύλο', '* Ύψος', '* Ηλικία'],
        },
        placeholder: ['Παρακαλώ εισάγετε όνομα', 'Εισάγετε ύψος', 'Παρακαλώ εισάγετε ηλικία'],
        unit: ['μετρικό', 'αγγλικό', ''],
        sexOptions: {
            male: 'Άνδρας',
            female: 'Γυναίκα',
        },
        btn: 'Υποβολή',
        rules: {
            metricheight: [`Το εύρος ύψους είναι από 2'3'' - 6'7'', παρακαλώ εισάγετε το ξανά.`],
            heightMsg: [`Το εύρος ύψους είναι από 70 - 200cm, παρακαλώ εισάγετε το ξανά.`],
            ageMsg: [`Το εύρος ηλικίας είναι από 10 έως 99, παρακαλώ εισάγετε το ξανά.`],
            agePointMsg: ['Η ηλικία μπορεί να είναι μόνο ακέραιος αριθμός, παρακαλώ συμπληρώστε τη σωστή ηλικία.'],
        },
        success: 'Η εγγραφή πραγματοποιήθηκε με επιτυχία',
        userMessage: 'Εισαγωγή Ονόματος Χρήστη',
        verify: 'Εισαγωγή 4 Ψηφίων Κωδικού Επαλήθευσης',
        errorMsg: 'Η μορφή ύψους δεν υποστηρίζει δεκαδικά ψηφία, παρακαλώ εισάγετε το ξανά',
        abnormalMsg: 'Ανωμαλία Εγγραφής',
    },
    // 模型合成
    model: {
        loading: 'Φόρτωση... Παρακαλώ περιμένετε.',
        modelLoading: 'Φόρτωση Μοντέλου 3D...',
        tabPane: [
            {
                title: 'Τεχνολογία βαθιάς επίγνωσης οντοτήτων',
                msg: 'Συλλέξτε με ακρίβεια τοπικές λεπτομέρειες του σώματος μέσω τρισδιάστατης σάρωσης, εξασφαλίστε την ακρίβεια μέτρησης περιφέρειας σε επίπεδο χιλιοστού και παρουσιάστε ξεκάθαρα τις αλλαγές στο σχήμα του σώματος μετά την άσκηση.',
            },
            {
                title: 'Αλγόριθμος Σύνθεσης Σώματος BDA',
                msg: 'Το Visbody χρησιμοποιεί το πιο προηγμένο σύστημα μέτρησης σύνθεσης σώματος - τον αλγόριθμο BDA για τον προσδιορισμό της σύνθεσης του σώματος. Αυτή η μέθοδος υπολογισμού που βασίζεται στον όγκο του σώματος θα αξιολογήσει με ακρίβεια τους κινδύνους υγείας που προκαλούνται από την παχυσαρκία.',
            },
            {
                title: 'Παράγοντες που προκαλούν σφάλματα',
                msg: 'Τα χαλαρά ρούχα ή ορισμένα διακοσμητικά στο σώμα θα προκαλέσουν σφάλματα, παρακαλώ φορέστε εφαρμοστά ρούχα ή μειώστε την ποσότητα ρούχων στο σώμα σας εάν θέλετε να λάβετε τα πιο ακριβή δεδομένα.',
            },
            {
                title: 'Λάβετε την αναφορά σας',
                msg: 'Συνιστάται να προσθέσετε σελιδοδείκτη στη διεύθυνση URL της ιστοσελίδας για εύκολη προβολή της αναφοράς στο μέλλον.Μπορείτε επίσης να στείλετε αυτήν την αναφορά στο email σας για να την αποθηκεύσετε αργότερα.',
            },
        ],
        collect: ['Προσθέστε σελιδοδείκτη σε αυτή τη σελίδα, για εύκολη ανασκόπηση της αναφοράς στο μέλλον.', ''],
        know: 'Κατάλαβα',
        reminder: [
            'Ευγενική Υπενθύμιση',
            'Το 3D avatar μοντέλο σας έχει δημιουργηθεί, σύρετε αριστερά και δεξιά για να περιστρέψετε το μοντέλο.',
            'Η αναφορά σας έχει δημιουργηθεί και μπορείτε να την δείτε κάνοντας κλικ στο κουμπί "Προβολή Αναφοράς".',
            'Η αξιολόγηση του σώματός σας δεν είναι επιτυχής, παρακαλώ επανεξετάσε την αναφορά σύνθεσης σώματος.',
            'Η σύνθεση του σώματός σας δεν ήταν επιτυχής, παρακαλώ πολύ ανατρέξτε στην έκθεση αναφοράς αξιολόγησης της στάσης του σώματος .',
            'Η μέτρησή σας δεν είναι επιτυχής, παρακαλώ πηγαίνετε στη συσκευή και δοκιμάστε ξανά.~',
            'Η μέτρηση σύνθεσης σώματος δεν είναι επιτυχής, παρακαλώ πηγαίνετε στη συσκευή και δοκιμάστε ξανά.~',
            'Η αξιολόγηση στάσης δεν είναι επιτυχής, παρακαλώ πηγαίνετε στη συσκευή και δοκιμάστε ξανά.~',
        ],
        btn: {
            viewReport: 'Προβολή Αναφοράς',
            bodyReport: 'Προβολή Αναφοράς Σύνθεσης Σώματος',
            postureReport: 'Προβολή Αναφοράς Στάσης Σώματος',
            lastReport: 'Προβολή Τελευταίας Αναφοράς',
            loading: 'Φόρτωση...',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'Λήψη Αναφοράς',
            send: 'Αποστολή Αναφοράς',
            report: 'Αποστολή Αναφοράς',
        },
        reportSuccess: 'Επιτυχία!',
        tabPane: {
            labelList: ['Χρόνος Μέτρησης', 'Στοιχείο', 'Σύσταση Σώματος', 'Αξιολόγηση Στάσης', 'Λειτουργία Ώμου'],
        },
        sendEmail: 'Ηλεκτονικό Ταχυδρομείο :',
        requiredMsg: 'Εισαγάγετε τη διεύθυνση email σας',
        patternMsg: 'Παρακαλώ εισάγετε τη σωστή διεύθυνση Email.',
        send: 'Αποστολή',
        download: 'Λήψη Αναφοράς',
        sendReport: 'Αποστολή Αναφοράς',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'Το φύλο άλλαξε με επιτυχία',
            height: 'Το ύψος άλλαξε με επιτυχία',
            age: 'Η ηλικία άλλαξε με επιτυχία',
        },
        logOut: 'Αποσύνδεση',
        personal: 'Προσωπικό Κέντρο',
        cut: 'Εναλλαγή μονάδων/γλωσσών',
        changeSort: 'Αλλαγή Αναφοράς Προϊόντος',
        describe:
            'Μετά την αλλαγή του μοντέλου προϊόντος, θα εμφανιστούν όλες οι αναφορές μετρήσεων υπό αυτό το μοντέλο.',
        describeBtn:
            'Μετά την αλλαγή του μοντέλου προϊόντος, θα ανακατευθυνθείτε στην πιο πρόσφατη αναφορά μέτρησης υπό αυτό το μοντέλο.',
        descReport: 'Επιτυχής Αλλαγή Αναφοράς Προϊόντος',
        unit: ['Μετρικό σύστημα μέτρησης(kg, cm)', 'Αγγλοσαξονικό σύστημα μέτρησης (ft, in, lb)'],
        read: {
            reading: 'Ανάγνωση',
            privacy: '(Πολιτική Απορρήτου)',
        },
        setting: 'Ρυθμίσεις',
        form: {
            labelList: ['Τηλέφωνο', 'Μονάδα', 'Γλώσσες', 'Ψευδώνυμο', 'Φύλο', 'Ύψος', 'Ηλικία'],
        },
        placeholder: ['Αλλαγή Ονόματος Χρήστη', 'Νέο Φύλο', 'Το Ύψος Ενημερώθηκε', 'Η Ηλικία Ενημερώθηκε'],
        btn: {
            cancelBtn: 'Άκυρο',
            accomplishBtn: 'Ολοκλήρωση',
            confirmBtn: 'Εντάξει',
        },
        dialog: [
            'Το φύλο είναι ένα από τα πιο σημαντικά κριτήρια για τη μέτρηση της σύστασης του σώματος.Τα δεδομένα που μετρήθηκαν μετά την τροποποίηση θα είναι διαφορετικά από τα προηγούμενα δεδομένα. Επιβεβαιώστε εάν θέλετε να κάνετε αλλαγή.',
            'Το ύψος είναι ένα από τα πιο σημαντικά κριτήρια για τη μέτρηση της σύστασης του σώματος. Τα δεδομένα που μετρήθηκαν μετά την τροποποίηση θα είναι διαφορετικά από τα προηγούμενα δεδομένα. Επιβεβαιώστε εάν θέλετε να κάνετε αλλαγή.',
            'Η ηλικία είναι ένα από τα πιο σημαντικά κριτήρια για τη μέτρηση της σύστασης του σώματος. Τα δεδομένα που μετρήθηκαν μετά την τροποποίηση θα είναι διαφορετικά από τα προηγούμενα δεδομένα. Επιβεβαιώστε εάν θέλετε να κάνετε αλλαγή.',
        ],
        rules: {
            metricheight: [`Παρακαλώ συμπληρώστε το σωστό εύρος ύψους (2'3" έως 6'7").`],
            heightMsg: ['Παρακαλώ συμπληρώστε το ύψος εντός 70cm - 200cm'],
            ageMsg: ['Παρακαλώ συμπληρώστε την ηλικία εντός 10 - 99 ετών.'],
        },
        heightMsg: 'Παρακαλώ Επιλέξτε Ξανά το Ύψος',
        ageMsg: 'Παρακαλώ Επιλέξτε Ξανά την Ηλικία',
        ModelBinding: 'Φαίνεται ότι κάποιος έχει ήδη σαρώσει τον κωδικό, παρακαλώ μετρήστε ξανά!',
        ReportEmpty: {
            title: 'Δεν έχετε καμία αναφορά ακόμα.',
            desc: 'Ελάτε στο σαρωτή σώματος 3D της Visbody και δοκιμάστε ξανά!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'Φαίνεται ότι κάποιος έχει ήδη σαρώσει τον κωδικό, παρακαλώ μετρήστε ξανά!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: 'Δεν έχετε καμία αναφορά ακόμα.',
        desc: 'Ελάτε στο σαρωτή σώματος 3D της Visbody και δοκιμάστε ξανά!',
        descS30: '找到 Visbody-S30 3D 体测精灵，进行一次测量吧！',
        descM30: '找到 Visbody-M30 3D 体测精灵，进行一次测量吧！',
    },
    // 用户信息
    userInfo: {
        height: 'Ύψος:',
        age: 'Ηλικία:',
        unit: '',
        weight: 'Βάρος',
    },
    // 体成分
    mass: {
        title: 'Επισκόπηση Αξιολόγησης Σύνθεσης Σώματος',
        titles: 'Σύνθεση Σώματος',
        springFrame: '',
        massFrame: 'Δεν υπάρχουν δεδομένα μέτρησης για την ημέρα',
        contrast: 'Επιλέξτε μια αναφορά για σύγκριση',
        contrastReport: 'Επιλέξτε μια αναφορά για σύγκριση',
        noRecord: 'Κενό',
        score: 'Βαθμολογία',
        status: 'Η τρέχουσα κατάσταση της σύνθεσης του σώματός σας',
        WT: 'Βάρος',
        PBF: 'Ποσοστό Λίπους',
        endSymbol: '.',
        // 测量相关
        measure: {
            value: 'Τιμές',
            standardRange: 'Τυπικό Εύρος',
            comparedWithLastLime: 'Καθαρό',
            comparedNet: 'Καθαρό',
            current: 'Τρέχουσα βαθμολογία μέτρησης',
            WT: 'Βάρος',
            FFM: 'Άπαχη Μάζα',
            BFM: 'Λιπώδης Μάζα',
            LM: 'Μυϊκή Μάζα',
            TBW: 'Συνολικό Νερό',
            SM: 'Σκελετικοί Μύες',
            PROTEIN: 'Πρωτεΐνη',
            TM: 'Ανόργανα Άλατα',
            BMR: 'Βασικός Μεταβολισμός',
            WHR: 'Αναλογία Μέσης-Ισχίου',
            BMI: 'ΔΜΣ',
            PBF: 'Ποσοστό Λίπους',
            VFG: 'Επίπεδο Σπλαχνικού Λίπους',
            unit: 'κιλά',
            BMRUnit: 'kcal/ ημέρα',
        },
        // 定义解读
        explain: [
            {
                title: 'Βάρος',
                msg: 'Το βάρος είναι το άθροισμα νερού, πρωτεΐνης, αλάτων και λίπους σώματος.',
            },
            {
                title: 'Άπαχη Μάζα',
                msg: 'Η άλιπη μάζα είναι το βάρος χωρίς λίπος.',
            },
            {
                title: 'Λιπώδης Μάζα',
                msg: 'Το σωματικό λίπος είναι το άθροισμα υποδόριου, σπλαχνικού και ενδομυϊκού λίπους.',
            },
            {
                title: 'Μυϊκή Μάζα',
                msg: 'Η μυϊκή μάζα περιλαμβάνει σκελετικούς, λείους και καρδιακούς μύες.',
            },
            {
                title: 'Συνολικό Νερό',
                msg: 'Το νερό αποτελεί 50-70% του σώματος, κυρίως στα κύτταρα και υγρά, με το περισσότερο στους μύες.',
            },
            {
                title: 'Σκελετικοί Μύες',
                msg: 'Οι σκελετικοί μύες (ή γραμμωτοί) προσκολλώνται στα οστά. Εδώ υπολογίζεται η ποσότητά τους.',
            },
            {
                title: 'Πρωτεΐνη',
                msg: 'Η πρωτεΐνη, αζωτούχα στερεά ουσία, υπάρχει σε όλα τα κύτταρα και είναι κύριο συστατικό των μυών.',
            },
            {
                title: 'Ανόργανα Άλατα',
                msg: 'Το σώμα αποτελείται από οργανική, ανόργανη ύλη και νερό. Τα ανόργανα άλατα αποτελούν το 5% του βάρους.',
            },
            {
                title: 'Βασικός Μεταβολισμός',
                msg: 'Ο βασικός μεταβολισμός είναι η ενέργεια που καταναλώνεται σε κατάσταση ηρεμίας, χωρίς εξωτερικές επιδράσεις.',
            },
            {
                title: 'Αναλογία Μέσης-Ισχίου',
                msg: 'Η αναλογία μέσης-ισχίου είναι δείκτης κεντρικής παχυσαρκίας.',
            },
            {
                title: 'ΔΜΣ',
                msg: 'Ο ΔΜΣ αξιολογεί την παχυσαρκία και είναι διεθνές πρότυπο μέτρησης σωματικού λίπους.',
            },
            {
                title: 'Ποσοστό Λίπους',
                msg: 'Το ποσοστό σωματικού λίπους είναι η αναλογία λίπους στο συνολικό βάρος.',
            },
            {
                title: 'Επίπεδο Σπλαχνικού Λίπους',
                msg: 'Ο βαθμός σπλαχνικού λίπους είναι ένας σημαντικός δείκτης για την αξιολόγηση της κρυφής παχυσαρκίας.',
            },
            {
                title: 'Ενδοκυττάριο νερό',
                msg: 'Ενδοκυτταρικό Νερό : Είναι το σωματικό υγρό που περιέχεται στα κύτταρα του σώματος και αποτελεί το βασικό συστατικό του πρωτοπλάσματος.',
            },
            {
                title: 'Εξωκυττάριο νερό',
                msg: 'Εξωκυτταρικό Νερό : Συνήθως αναφέρεται σε εξωκυτταρικά σωματικά υγρά, συμπεριλαμβανομένου του πλάσματος και του ενδιάμεσου υγρού μεταξύ των αιμοφόρων αγγείων και των κυττάρων των ιστών.',
            },
            {
                title: 'Μεταβολική ηλικία',
                msg: 'Η μεταβολική ηλικία αφορά την απόδοση του σώματος και τις λειτουργίες υγείας που σχετίζονται με τον μεταβολισμό.',
            },
        ],
        // 节段
        segment: {
            fat: 'Κατανομή λίπους ανά τμήμα',
            muscle: 'Κατανομή μυϊκής μάζας ανά τμήμα',
            right: 'Δεξιά',
            left: 'Αριστερά',
            standard: 'Κανονικός',
            lowStandard: 'Κάτω από',
            superStandard: 'Πάνω',
            peel: '(απόβαρο)',
        },
    },
    // 体态
    shape: {
        title: 'Επισκόπηση Αξιολόγησης Σώματος',
        titles: 'Στάση',
        status: 'Η τρέχουσα φυσική κατάστασή σας',
        suggest: 'Πρόταση',
        possibility: ', υπάρχει πιθανότητα για',
        models: {
            front: 'Θετικός',
            left: 'Αριστερό προφίλ',
            right: 'Δεξί προφίλ',
            top: 'Πίσω',
            low: 'Κάτω Από',
            high: 'Πάνω Από',
            normal: 'Φυσιολογικό',
        },
        item: {
            deviate: 'Τιμές',
            result: 'Εξήγηση',
            normal: 'Φυσιολογικό',
			keAbnormal: 'Πιθανή ανωμαλία',
            abnormal: 'Μη κανονικός',
            head: 'Πρόσθια Κλίση Κεφαλής',
            headSlant: 'Πλάγια Κλίση Κεφαλής',
            roundShoulderLeft: 'Κύρτωση Αριστερού Ώμου',
            roundShoulderRight: 'Κύρτωση Δεξιού Ώμου',
            highLowShoudler: 'Ασύμμετροι Ώμοι',
            pelvis: 'Πρόσθια/Οπίσθια Κλίση Λεκάνης',
            leftKneeCheck: 'Αξιολόγηση Αρ. Γονάτου',
            rightKneeCheck: 'Αξιολόγηση Δεξ. Γονάτου',
            leg: 'Τύπος Ποδιών',
            leftLeg: 'Αρ. πόδι:',
            rightLeg: 'Δεξ. πόδι:',
        },
        // 定义解读
        explain: [
            {
                title: 'Πρόσθια Κλίση Κεφαλής',
                msg: 'Η γωνία μεταξύ του σημείου του αυτιού στην αριστερή πλευρά και της γραμμής που συνδέει το κέντρο του λαιμού και της μέσης γραμμής στο πλάι',
            },
            {
                title: 'Πλάγια Κλίση Κεφαλής',
                msg: 'Η γωνία μεταξύ του αριστερού σημείου του αυτιού και της κεντρικής γραμμής του λαιμού και της μέσης γραμμής της πλευράς',
            },
            {
                title: 'Κύρτωση Αριστερού Ώμου',
                msg: 'Η γωνία μεταξύ του υψηλότερου σημείου της αριστερής πλευράς της πλάτης και της εφαπτομένης γραμμής του ώμου',
            },
            {
                title: 'Κύρτωση Δεξιού Ώμου',
                msg: 'Η γωνία μεταξύ του υψηλότερου σημείου της δεξιάς πλευράς της πλάτης και της εφαπτομένης γραμμής του ώμου',
            },
            {
                title: 'Ασύμμετροι Ώμοι',
                msg: 'Η κάθετη απόσταση μεταξύ των σημείων του αριστερού και δεξιού ώμου με την πλάτη',
            },
            {
                title: 'Πρόσθια/Οπίσθια Κλίση Λεκάνης',
                msg: 'Η γωνία που συμπεριλαμβάνει τα τρία σημεία και συνδέει το κέντρο του λαιμού , το σημείο του ισχίου και το σημείο του αστραγάλου στην αριστερή πλευρά .',
            },
            {
                title: 'Αξιολόγηση Αρ. Γονάτου',
                msg: 'Η γωνία της γραμμής τριών σημείων που συνδέει το οστό του ισχίου, την άρθρωση του γόνατος και το σημείο του αστραγάλου στην αριστερή πλευρά',
            },
            {
                title: 'Αξιολόγηση Δεξ. Γονάτου',
                msg: 'Η γωνία της γραμμής τριών σημείων που συνδέει το οστό του ισχίου, την άρθρωση του γόνατος και το σημείο του αστραγάλου στη δεξιά πλευρά',
            },
            {
                title: 'Τύπος Ποδιών',
                msg: 'Η γωνία μεταξύ των σημείων των οστών του ισχίου, των αρθρώσεων του γόνατος και των σημείων του αστραγάλου στο μπροστινό μέρος του αριστερού και του δεξιού ποδιού',
            },
        ],
        exception: [
            {
                title: 'Κλίση προς την Αριστερή Πλευρά',
                abnormal: '',
            },
            {
                title: 'Κλίση προς τη Δεξιά Πλευρά',
                abnormal: '',
            },
            {
                title: 'Αριστερά ψηλά',
                abnormal: '',
            },
            {
                title: 'Δεξιά ψηλά',
                abnormal: '',
            },
            {
                title: 'Οπίσθια Κλίση της Λεκάνης',
                abnormal: '',
            },
            {
                title: 'Εμπρόσθια Κλίση της Λεκάνης',
                abnormal: '',
            },
            {
                title: 'Υπερέκταση του Αριστερού Γόνατου',
                abnormal: '',
            },
            {
                title: 'Αριστερό Γόνατο Κάμψη Προς Τα Εμπρος',
                abnormal: '',
            },
            {
                title: 'Υπερέκταση του Δεξιού Γόνατου',
                abnormal: '',
            },
            {
                title: 'Δεξί Γόνατο Κάμψη Προς Τα Εμπρος',
                abnormal: '',
            },
            {
                title: 'Πόδια σε Σχήμα Κ',
                abnormal: '',
            },
            {
                title: 'Πόδια σε Σχήμα D',
                abnormal: '',
            },
            {
                title: 'Πόδια σε Σχήμα Χ',
                abnormal: '',
            },
            {
                title: 'Πόδια σε Σχήμα Ο',
                abnormal: '',
            },
        ],
    },
    newshape: {
        explain: [
            {
                msg: 'Κάθετη απόσταση μεταξύ των αυτιών και της γραμμής βαρύτητας του σώματος',
            },
            {
                msg: 'Γωνία μεταξύ της γραμμής που συνδέει τα δύο αυτιά και του οριζόντιου επιπέδου',
            },
            {
                msg: 'Κάθετη απόσταση μεταξύ της αριστερής ακρωμιακής απόφυσης και της γραμμής βαρύτητας',
            },
            {
                msg: 'Κάθετη απόσταση μεταξύ της δεξιάς ακρωμιακής απόφυσης και της γραμμής βαρύτητας',
            },
            {
                msg: 'Διαφορά στις γραμμές μεταξύ της βάσης του αυχένα και των ακρωμιακών αποφύσεων',
            },
            {
                msg: 'Κάθετη απόσταση άρθρωσης ισχίου και γραμμής βαρύτητας',
            },
            {
                msg: 'Γωνία στον οβελιαίο επίπεδο μεταξύ ποδοκνημικής και γόνατος αριστερού',
            },
            {
                msg: 'Γωνία στον οβελιαίο επίπεδο μεταξύ ποδοκνημικής και γόνατος δεξιού',
            },
            {
                msg: 'Γωνία μεταξύ των αμφοτερόπλευρων αρθρώσεων του ισχίου, των γονάτων και των αστραγάλων στο μετωπιαίο επίπεδο',
            },
        ],
        item: {
            pelvis: 'Πρόσθια κλίση της πυέλου',
        },
    },
    // 围度信息
    girth: {
        title: 'Περίμετροι',
        present: '(Παρόν)',
        noHistory: 'Χωρίς δεδομένα.',
        tpl: [
            {
                title: 'Περίμετρος Λαιμού',
                key: 'neckGirth',
            },
            {
                title: 'Περ. Αρ. Βραχίονα',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'Περ. Δεξ. Βραχίονα',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'Περ. Στήθους',
                key: 'bustGirth',
            },
            {
                title: 'Περ. Άνω Μέσης',
                key: 'waistGirth',
            },
            {
                title: 'Περ. Μέσης',
                key: 'midWaistGirth',
            },
            {
                title: 'Περ. Γοφών',
                key: 'hipGirth',
            },
            {
                title: 'Περ. Αρ. Μηρού',
                key: 'leftThighGirth',
            },
            {
                title: '左大腿中部围',
                key: 'leftMidThighGirth',
            },
            {
                title: 'Ελάχ. Περ. Αρ. Μηρού',
                key: 'leftMinThighGirth',
            },
            {
                title: 'Περ. Δεξ. Μηρού',
                key: 'rightThighGirth',
            },
            {
                title: '右大腿中部围',
                key: 'rightMidThighGirth',
            },
            {
                title: 'Ελάχ. Περ. Δεξ. Μηρού',
                key: 'rightMinThighGirth',
            },
            {
                title: 'Περ. Αρ. Κνήμης',
                key: 'leftCalfGirth',
            },
            {
                title: 'Περ. Δεξ. Κνήμης',
                key: 'rightCalfGirth',
            },
            {
                title: 'Χαμηλή μέση',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'Λειτουργία Ώμου',
        model: {
            val: 'Τιμές',
            scope: 'Τοποθεσία μέγιστης δραστηριότητας',
            diff: 'Καθαρό',
        },
        normal: 'Φυσιολογικό',
        normalAll: 'Δεν εντοπίστηκαν σημαντικές ανωμαλίες στη λειτουργία των ώμων.',
        conclusion: 'Συμπέρασμα',
        analyse: 'Ανάλυση: ',
        suggest: 'Πρόταση: ',
        cause: 'Παρακαλώ συμβουλευτείτε με ειδικούς για λεπτομερείς λόγους.',
    },
    reportTips: {
        title: 'Παρακαλούμε επικοινωνήστε με τον διαχειριστή αν θέλετε να διαγράψετε την αναφορά.',
        btnMsg: 'Εντάξει',
        alaryTips: 'Αυτή η αναφορά διαγράφηκε από τον διαχειριστή και θα μεταφερθείτε στην επόμενη αναφορά!',
    },
    saveQr: {
        title: 'Αναφορά Σαρωτή Σώματος 3D Visbody',
        btnMsg: 'Κρατήστε πατημένη την εικόνα για να την αποθηκεύσετε στο τηλέφωνό σας.',
        tips: 'Αν δεν μπορείτε να αποθηκεύσετε, παρακαλούμε πηγαίνετε στις [Ρυθμίσεις] και ανοίξτε τις αντίστοιχες άδειες.',
    },
    vanCalendar: {
        end: 'End',
        start: 'Start',
        title: 'Calendar',
        startEnd: 'Start/End',
        weekdays: ['Κυρ', 'Δευ', 'Τρι', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'],
        monthTitle: function monthTitle(year, month) {
            return year + '/' + month
        },
        rangePrompt: function rangePrompt(maxRange) {
            return 'Choose no more than ' + maxRange + ' days'
        },
    },
    share: {
        title: 'Παρακαλούμε αντιγράψτε και κοινοποιήστε τον παρακάτω σύνδεσμο.',
        btnMsg: 'Αντιγραφή',
    },
    ...elLocale,
    ...agrEn,
}
