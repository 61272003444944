<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2022-06-30 16:05:19
 * @LastEditors: liutq
 * @LastEditTime: 2025-01-04 17:18:30
-->
<template>
    <!--选择时间-->
    <div>
        <van-calendar :default-date="new Date(defaultDate)" :min-date="minDate" :max-date="maxDate" :show-mark=false
            :title="$t('mass.contrastReport')" v-model="showTable" :formatter="formatter" @confirm="onConfirm"
            :confirmText="$t('sidebar.btn.confirmBtn')"></van-calendar>
    </div>
</template>
<script>
import { mapState } from "vuex"
export default {
    props: {
        isShow: Boolean,
        defaultDate: String,
        shortType: Number
    },
    data() {
        return {
            minDate: new Date(2010, 0, 1),
            maxDate: new Date(2099, 0, 31),
            dateTimeArray: []
        };
    },
    computed: {
        ...mapState(['dataArray', 'dataArrayMass']),
        showTable: {
            get() {
                return this.isShow
            },
            set() {
                this.$emit('isSure')
            }
        }
    },
    methods: {
        onConfirm(date) {
 
            let flag = true
            for (const item of this.dateTimeArray) {
				// 判断日期是否匹配
				if (item.createDate === this.$moment(date).format("YYYY-MM-DD")) {
					if (flag) {
						// 确定日期格式
						const formattedDate = this.$i18n.locale === 'ru-RU' || this.$i18n.locale === 'ar-AR'
							? this.$moment(date).format("DD/MM/YYYY")
							: this.$moment(date).format("YYYY/MM/DD");

						// 触发事件并传递参数
						this.$emit('isSure', item.scanId, formattedDate);

						// 标记已处理，防止重复触发
						flag = false;
					}
					break; // 匹配成功后直接退出循环，提升性能
				}
			}
            if (flag) {
                this.$toast(this.$t('mass.massFrame'));
            }
            // this.$emit('isSure', undefined, this.$moment(date).format("YYYY/MM/DD"))
        },
        formatter(day) {
            if (this.shortType === 1) {
                this.dateTimeArray = this.dataArrayMass
            } else {
                this.dateTimeArray = this.dataArray
            }
            const month = day.date.getMonth() + 1;
            const date = day.date.getDate();
            const year = day.date.getYear()
            if (this.dateTimeArray.length != 0) {
                for (let index = 0; index < this.dateTimeArray.length; index++) {
                    const data = this.dateTimeArray[index].createDate.split('-')
                    if (year + 1900 == parseInt(data[0])) {
                        if (month == parseInt(data[1])) {
                            if (date === parseInt(data[2])) {
                                day.topInfo = ' ';
                            }
                        }
                    }
                }
            }

            return day;
        },
    },
}
</script>
<style lang="less" scoped>
/deep/.van-popup--bottom.van-popup--round {
    border-radius: 0px !important;
}

/deep/.van-calendar__header-title {
    color: #ffffff;
    background: #323161;
}

/deep/ .van-calendar__header-subtitle {
    background: #27284f;
    color: #8AA3BE;
}

/deep/ .van-calendar__weekdays {
    background: #27284f;
    color: #8AA3BE;
}

/deep/ .van-calendar__body {
    background: #27284d;
}

/deep/ .van-calendar__footer {
    background: #27284d;
}

/deep/ .van-button--danger {
    color: #000;
    background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
    border: none;
}

/deep/ .van-button--round {
    border-radius: 4px;
}

/deep/ .van-calendar__day--multiple-selected {
    width: 32px;
    height: 32px;
    border: 1px solid;
    border-image: linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1)) 1 1;
}

/deep/ .van-calendar__day--end {
    border-image: linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1)) 1 1;
}

/deep/ .van-calendar__days,
.van-calendar__month-title {
    color: #8AA3BE;
}

/deep/ .van-calendar__month-title {
    color: #8AA3BE;
    font-weight: bold;
}

/deep/ .van-calendar__top-info {
    background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid #00E3C9;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    // position: relative;
    left: 12px;
    top: 17px
}


   

/deep/ .van-calendar__selected-day {
    background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    // .van-calendar__top-info{
    //     display: none
    // }
}



</style>
