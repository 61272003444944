<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2022-06-27 13:59:40
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-07-19 10:04:45
-->
<template>
  <!--身体成分上半部分-->
  <div :class="['body-composition body-thermal-wrapper', $i18n.locale]">
    <div class="report-contrast">
      <div class="contrast-title">
        <span>{{ $t('mass.contrast') }}</span>
        <date-select :type="1" :isCreateTime="isCreateTime" @girthContras="onGetMass"></date-select>
      </div>
    </div>
  </div>
</template>
<script>
import { findBmMassQuotaInfos } from '@/assets/js/apolloGql.js'
import { clone, _toDecimal} from '@/assets/js/util.js'
export default {
    components: {
        DateSelect: () => import('@/components/common/DateSelect')
    },
    props: {
        isCreateTime: String,
        isHistoryDate: Array
    },
    data() {
        return {
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            popupVisible: false,
            unit: window.localStorage.getItem('unit'),
        }
    },
    mounted() {
        // 开启加载动画
        // this.$indicator.open({
        //     spinnerType: 'fading-circle'
        // })
        // window.sessionStorage.removeItem('trendData')
    },
    methods: {
        handleCommand(command) {
            this.$message('click on item ' + command);
        },
        // 关闭时间组件
        handleClose() {
            this.selectTime = this.originDate
            for (let i = 0; i < this.timeList.length; i++) {
                for (let j = 0; j < this.timeList[i].options.length; j++) {
                    this.timeList[i].options[j].isChecked =
                        this.originNoCheckScanIds.indexOf(this.timeList[i].options[j].scanId) === -1
                }
            }
            this.popupVisible = false
        },
        handleSureTime() {
            this.popupVisible = false
            this.currentDate = this.selectTime
            this.originDate = this.selectTime
            this.saveStorage(this.selectTime, this.tcScanId)
            if (this.selectTime) {
                this.findBmMassQuotaTrend()
            }
        },
        toDecimal(num, digit) {
            return _toDecimal(num * 0.45359237, digit)
        },
        toDecimal2(x, y) {
            let f = Math.round(x * 100) / 100
            let s = f.toString()
            let rs = s.indexOf('.')
            if (rs < 0) {
                rs = s.length
                s += '.'
            }
            while (s.length <= rs + y) {
                s += '0'
            }
            return s
        },
        // 获取数据
        onGetMass(tcScanId) {
            this.$apollo
                .query({
                    query: findBmMassQuotaInfos,
                    variables: {
                        memberId: this.modelInfo.memberId,
                        scanId: window.localStorage.getItem('BmLastScanId'),
                        tcScanId: tcScanId,
                        deviceType: JSON.parse(window.localStorage.getItem('deviceType'))
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    const data = res.data.findBmMassQuotaInfos
                    if (data.code === 200) {
                        const newArray = clone(data.data)
                        newArray.forEach(item => {
                            if(item.order === -1){
                                return
                            }
                            if (item.contrastData) {
                                item.contrast = item.contrastData.v
                            } else {
                                item.contrast = null
                            }
                            if (this.unit === 'metric' && item.unit === 'kg') {
                                if (item.latestData) item.latestData.v = this.toDecimal(item.latestData.v, 1)
                                if (item.contrastData) {
                                    item.contrastData.v = this.toDecimal(item.contrastData.v, 1)
                                    item.contrast = item.contrastData.v || null
                                }
                            }
                            item.lates = item.latestData.v || null
                            if (this.unit === 'imperial' && item.unit === 'kg') {
                                item.unit = 'lbs'
                            }
                        })

                        const age = newArray.filter((item) => item.dataColumn === 'METABOLICAGE')
                        const newIndex = newArray.findIndex(item => item.dataColumn === 'BMR')
                        newArray.splice(newIndex+1, 0, age[0])
                        newArray.splice(newArray.length - 2)

                        setTimeout(()=>{
                            this.$store.commit("setComposition", newArray)
                        }, 100)
                    } else {
                        this.$toast({
                            message: this.$t('mass.springFrame'),
                            duration: 2000,
                        });
                    }
                })
        }
    }
}
</script>
<style lang="less" scoped>
.report-contrast {
    width: 353px;
    height: 75px;
    margin-top: 14px;
    padding: 0 10px;
    background: url(../../assets/images/report/bj2_en.png) center no-repeat;
    background-size: 100% 100%;
    .contrast-title {
        span {
            display: inline-block;
            margin-top: 8px;
            font-size: 14px;
            color: #8aa3be;
            font-weight: 400;
            line-height: 14px;
        }
    }
}

&.ru-RU,&.tr-TR,&.pt-PTO,&.el-GR {
    .contrast-title {
        span {
            font-size: 12.5px;
        }
    }
}
&.de-DE {
    .contrast-title {
        span {
            font-size: 10.5px;
        }
    }
}
&.id-ID,.fr-FR{
    .contrast-title {
        span {
            font-size: 13px;
        }
    }
}
.pt-PT {
    .contrast-title {
        span {
            font-size: 12px;
        }
    }
}
.date-select {
    display: flex;
    justify-content: space-around;
    line-height: 58px;
    .date-select-first {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .date-select-second {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8aa3be;
    }
}
.el-dropdown-link {
    cursor: pointer;
    color: #409eff;
}
.el-icon-arrow-down {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.ar-AR{
 .contrast-title{
    direction: ltr;
}   
}

</style>

