<template>
    <div :class="['send-report', $i18n.locale]">
        <header>
            <div class="header-menu">
                <span>
                    <img src="@/assets/images/sidebar/arrow-left.png" alt class="menu-info" @click="goBack()" />
                </span>
            </div>
        </header>
        <main>
            <div class="scan-info">
                <p class="title">{{ $t('report.sendReport') }}</p>
                <div class="item-group">
                    <p>
                        <span class="label">{{ $t('report.tabPane.labelList[0]') }}：</span>
                        <span>{{ reportInfo.scanTime }}</span>
                    </p>
                    <p>
                        <span class="label">{{ $t('report.tabPane.labelList[1]') }}：</span>
                        <span class="success-item">{{ reportInfo.successItem }}</span>
                    </p>
                </div>
                <div class="form">
                    <div>
                        <van-field
                            v-model="email"
                            :label="$t('report.sendEmail')"
                            :placeholder="$t('report.requiredMsg')"
                            :style="{ background: '#303C5C', 'border-radius': '4px', padding: '4.667vw 4.267vw' }"
                            @input="search"
                            @focus="onclickInput"
                            @blur="checkEmail"
                            type="textarea"
                            class="textarea"
                        />
                    </div>

                    <div v-if="errMsg && !showHistoryEmail" class="err-msg">
                        <p>{{ errMsg }}</p>
                    </div>

                    <div class="email-history" v-if="showHistoryEmail && searchList.length" >
                        <p v-for="(item, index) in searchList" :key="index" @click="onclickEmail(item)">{{ item }}</p>
                    </div>

                    <div class="botton">
                        <van-button
                            color="linear-gradient(90deg, #00E3C9 0%, #009FE8 100%)"
                            :disabled="bottonDisabled"
                            :loading="sending"
                            block
                            @click="onSubmit"
                        >
                            {{ $t('report.send') }}
                        </van-button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { sendReportByMail } from '@/assets/js/apolloGql.js'

import { mapState } from 'vuex'
import {_parseTimezoneToMinutes } from '@/assets/js/util.js'

export default {
    name: 'sendReport',
    data() {
        return {
            // 输入的我邮箱
            email: '',
            // 按钮是否可点击
            bottonDisabled: true,
            // 错误消息
            errMsg: '',
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            reportInfo: {
                scanTime: '',
                successItem: '',
            },
            sending: false,
            list: [],
            searchList: [],
            // emailHistory: [
            //     '123@qq.com',
            //     'yangsheng@suanier.com',
            //     'fanyunbo@suanier.com',
            //     '456@qq.com',
            //     '789@qq.com',
            //     '000@qq.com',
            // ],
            showHistoryEmail: false,
            deviceType: JSON.parse(window.localStorage.getItem('deviceType')),
        }
    },
    computed: {
        ...mapState(['historyData']),
    },
    created() {
        if (this.historyData.createTime && this.$route.query.type == 1) {
            this.modelInfo = this.historyData
        }
		const timezone =_parseTimezoneToMinutes(this.modelInfo?.utc.replace(/^(UTC|GMT)/, '').trim())
        this.reportInfo.scanTime = this.$moment(this.modelInfo.createTime * 1000).utcOffset(timezone).format('YYYY-MM-DD HH:mm:ss')
        if (this.$i18n.locale === 'ru-RU'  || this.$i18n.locale === 'ar-AR') {
            this.reportInfo.scanTime = this.$moment(this.modelInfo.createTime * 1000).utcOffset(timezone).format('DD/MM/YYYY HH:mm')
        }
        let successArr = []
        if (this.$route.query.type == 1) {
            if (this.modelInfo.biaMeasure === 1) successArr.push(this.$t('report.tabPane.labelList[2]'))
            if (this.modelInfo.staticEval === 1) successArr.push(this.$t('report.tabPane.labelList[3]'))
            if (this.modelInfo.girthMeasure === 1 && this.deviceType === 3) {
                successArr.push(this.$t('girth.title'))
            }
            if (this.modelInfo.shoulder === 1) successArr.push(this.$t('report.tabPane.labelList[4]'))
        } else {
            if (this.modelInfo.biaStatus === 1) successArr.push(this.$t('report.tabPane.labelList[2]'))
            if (this.modelInfo.evalStatus === 1) successArr.push(this.$t('report.tabPane.labelList[3]'))
            if (this.modelInfo.bdaStatus === 1 && this.deviceType === 3) {
                successArr.push(this.$t('girth.title'))
            }
            if (this.modelInfo.bsShoulderScanResult === 1) successArr.push(this.$t('report.tabPane.labelList[4]'))
        }
        console.log(`当前报告名称${JSON.stringify(successArr)}  ${JSON.stringify(this.modelInfo)}`)
        this.reportInfo.successItem = successArr.join('、')
    },
    methods: {
        onclickInput() {
            console.log(JSON.parse(window.localStorage.getItem('emailHistory')))
            this.searchList = this.list = JSON.parse(window.localStorage.getItem('emailHistory')) || []
            this.showHistoryEmail = true
        },
        onclickEmail(email) {
            this.email = email
            this.showHistoryEmail = false
            this.checkEmail()
        },
        search(value) {
            let a = value //用户输入的字符串
            let str = ['', ...a, ''].join('.*') //转化成正则格式的字符串
            let reg = new RegExp(str) //正则
            this.searchList = this.list.filter((item) => reg.test(item))
        },
        goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        },
        checkEmail() {
            // this.showHistoryEmail = false
            const email = this.email
            const regName = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
            if (!email) {
                this.errMsg = this.$t('report.patternMsg')
            } else if (!regName.test(email)) {
                this.errMsg = this.$t('report.patternMsg')
            } else {
                this.errMsg = ''
                this.bottonDisabled = false
            }
            setTimeout(() => {
                this.showHistoryEmail = false
            }, 100)
        },
        async onSubmit() {
            if (this.errMsg) return
            console.log('发送报告', this.email)
            this.sending = true
            const result = await this.$apollo.mutate({
                mutation: sendReportByMail,
                variables: {
                    scanId: this.modelInfo.scanId,
                    email: this.email,
                    unit: localStorage.getItem('unit') || 'metric',
                },
            })
            const resultData = result.data.sendReportByMail
            this.sending = false
            if (resultData.code === 200) {
                this.$toast(this.$t('login.success'))
                // 去重后保存到localStorage
                const newList = [...new Set([...this.list, this.email])]
                localStorage.setItem('emailHistory', JSON.stringify(newList))
            } else {
                this.$toast(this.$t('login.error'))
            }
        },
    },
}
</script>

<style lang="less" scoped>
.send-report {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #262849, #07090f);

    header {
        // padding: 0 15px;
        .header-menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 15px 0 15px;
            width: 100%;
            top: 0;
            z-index: 100;

            > span {
                img {
                    display: block;
                }
            }

            .menu-info {
                width: 24px;
                height: 24px;
            }
        }
    }

    main {
        padding: 0 15px;

        .scan-info {
            // margin-top: 96px;

            .title {
                margin-bottom: 87px;
                font-size: 22px;
                color: #ffffff;
                // background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
            }

            .item-group {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // width: 345px;
                // height: 88px;
                // background: rgba(45, 47, 86, 0.4);
                background: #303c5c;
                border-radius: 4px;
                padding: 5px 16px;

                p {
                    position: relative;
                    line-height: 44px;
                    text-align: left;
                    font-size: 14px;
                    color: #ffffff;
                }

                .label {
                    color: rgba(255, 255, 255, 0.5);
                }

                p:nth-child(1)::after {
                    position: absolute;
                    box-sizing: border-box;
                    content: ' ';
                    pointer-events: none;
                    right: 0;
                    bottom: -0.5px;
                    left: 0;
                    border-bottom: 1.5px solid #ffffff;
                    -webkit-transform: scaleY(0.5);
                    transform: scaleY(0.5);
                    opacity: 0.2;
                }
            }

            .form {
                position: relative;
                margin-top: 12px;

                .err-msg {
                    position: absolute;
                    width: 100%;
                    margin-top: 12px;
                    font-size: 13px;
                    color: #fe6363;
                    text-align: center;
                }

                .botton {
                    margin-top: 57px;
                }

                /deep/ .van-field__label {
                    width: 88px !important;
                    color: #fff;
                }

                /deep/ .van-field__control {
                    color: #fff;
                }
            }

            .email-history {
                position: relative;
                width: 100%;
                margin-top: 1px;
                padding: 7px 16px;
                // background: rgba(45, 47, 86, 0.4);
                background: #303c5c;
                border-radius: 4px;

                p {
                    text-align: left;
                    font-size: 12px;
                    color: #ffffff;
                    line-height: 22px;
                }
            }
        }
    }

    &.ja-JP {
        /deep/ .van-field__control {
            width: 200px;
            font-size: 12px;
            line-height: 52px;
        }
    }

    &.en-US,
    &.it-IT,
    &.fr-FR,
    &.ru-RU,
    &.es-ES,
    &.pt-PT,
    &.el-GR {
        .item-group {
            .success-item {
                display: inline-flex;
                width: 240px;
                line-height: 18px;
            }
        }

        .van-button__text {
            color: #222222;
        }
    }
}
&.el-GR{
    /deep/ .van-field__label {
                    width: 88px !important;
                    color: #fff;
                    span{
                        width: 100px;
                        display: inline-block;
                    }
                }
}
&.ar-AR {
    direction: rtl;
    .item-group {
        p {
            text-align: right !important;
        }
    }
    /deep/.van-field__control {
        text-align: right !important;
        margin-right: 5px;
        direction: ltr;
    }
    .email-history {
        direction: ltr;
        p {
            text-align: right !important;
            margin-right: 10px;
        }
    }
    .van-field__control{
        direction: ltr;
    }
    
}
</style>
