<!--
 * @Description: 
 * @Author: fanyunbo
 * @Date: 2022-07-07 15:53:46
 * @LastEditTime: 2025-01-07 14:13:23
 * @LastEditors: liutq
 * @Reference: 
-->
<template>
    <div :class="['date-select', $i18n.locale]">
        <div :class="[qkStatus?'date-select-first-qk':'date-select-first', lineHeightStyle]" @click="selectDate()">
            {{ createDate }}<i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <div class="date-select-img">
            <img src="../../assets/images/path.png" alt="">
        </div>
        <div class="date-select-second">
            {{ isCreateTime }} {{ $t('girth.present') }}
        </div>
        <div>
            <trend-time :shortType=type :isShow="show" :defaultDate="defaultDate" @isSure="onShow"></trend-time>
        </div>
    </div>
</template>
<script>
import TrendTime from '@/components/time/Trend'
import { mapState } from "vuex"
import {_parseTimezoneToMinutes } from '@/assets/js/util.js'
export default {
    components: {
        TrendTime,
    },
    props: {
        isCreateTime: String,
        type: Number
    },
    data() {
        return {
            createDate: this.$t('mass.noRecord'),
            show: false,
            defaultDate: '',
            qkStatus: false
        }
    },
    computed: {
        ...mapState(['dataArray', 'dataArrayMass']),
        lineHeightStyle () {
            if(this.createDate === this.$t('mass.noRecord')) {
                return 'lineHeight'
            }
            return ''
        }
    },
    watch: {
        dataArray: {
            handler() {
                this.dataUpdate()
            }
        },
        deep: true,
        immediate: true
    },
    mounted() {
        var userAgent = navigator.userAgent;
        if(userAgent.indexOf('Quark')>-1){
            this.qkStatus = true
        }
        // 提成分调用
        if (this.type === 1) {
            this.dataUpdate()
        }
    },
    methods: {
        dataUpdate() {
            let newDate
            if (this.dataArray.length >= 1 && this.type !== 1) {
                newDate = this.dataArray
            } else if (this.dataArrayMass.length >= 1 && this.type === 1) {
                newDate = this.dataArrayMass
            }
            // console.log(`当前类型${this.type} ${this.dataArray.length}`)
            if (newDate && newDate.length >= 1) {
                // let i = 1
                // this.createDate = this.$moment(newDate[i].createTime * 1000).format("YYYY/MM/DD")
                // if (this.createDate == this.isCreateTime) {
                //     i = 0
                //     this.createDate = this.$moment(newDate[i].createTime * 1000).format("YYYY/MM/DD")
                // }
                // this.$emit('girthContras', newDate[i].scanId)
                let index = null
				
                for (let i = 0; i < newDate.length; i++) {
					const formattedTimezone = _parseTimezoneToMinutes(newDate[i].utc.replace(/^(UTC|GMT)/, '').trim())
                    if(this.$i18n.locale === 'ru-RU' || this.$i18n.locale === 'ar-AR'){
                        this.createDate = this.$moment(newDate[i].createTime * 1000).utcOffset(formattedTimezone).format("DD/MM/YYYY")
                    }else {
                        this.createDate = this.$moment(newDate[i].createTime * 1000).utcOffset(formattedTimezone).format("YYYY/MM/DD")
                    }
                    
                    if (this.createDate == this.isCreateTime) {
                        index = i
                        break
                    }
                }

                if (index === null || index === newDate.length - 1) {
                    this.createDate = this.$t('mass.noRecord')
                    this.defaultDate = this.isCreateTime
                    this.$emit('girthContras', null)
                } else {
					const dateItem = newDate[index + 1];
					const formattedTimezone = _parseTimezoneToMinutes(dateItem.utc.replace(/^(UTC|GMT)/, '').trim());
					const createDateCommon = this.$moment(dateItem.createTime * 1000).utcOffset(formattedTimezone);

					// 定义不同语言的日期格式
					const dateFormats = {
						"ru-RU": { display: "DD/MM/YYYY", default: "YYYY/MM/DD" },
						"ar-AR": { display: "DD/MM/YYYY", default: "YYYY/MM/DD" },
						default: { display: "YYYY/MM/DD", default: "YYYY/MM/DD" },
					};

					// 根据当前语言选择格式
					const currentFormat = dateFormats[this.$i18n.locale] || dateFormats.default;

					this.createDate = createDateCommon.format(currentFormat.display);
					this.defaultDate = createDateCommon.format(currentFormat.default);

					// 触发事件
					this.$emit('girthContras', dateItem.scanId);
                }
            } else {
                this.$emit('girthContras', null)
            }
        },
        selectDate() {
            this.show = true
        },
        onShow(scanId, data) {
            this.show = false
            if (!data && !scanId) {
                return
            }
            this.createDate = data
            if (data && scanId) {
                this.$emit("girthContras", scanId)
            }
        }
    }
}
</script>
<style scoped lang="less">
.date-select {
    position: relative;
    z-index: 8;
    display: flex;
    line-height: 58px;

    .date-select-first {
        width: 45%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .date-select-first-qk{
        width: 45%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color:  #009FE8;
    }

    .date-select-img {
        width: 10%;
        img {
            width: 18px;
            height: 16px;
        }
    }

    .date-select-second {
        width: 45%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8AA3BE;
    }
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

&.en-US,
&.it-IT,
&.fr-FR,
&.ru-RU,
&.es-ES,
&.pt-PT {
    .date-select-first {
        width: 40%;
    }

    .date-select-img {
        width: 20%;
    }

    .date-select-second {
        width: 43%;
    }
}
&.el-GR {
    .date-select-first {
        width: 40%;
        margin-top: 25px;
        line-height: 8px;
    }

    .date-select-img {
        width: 20%;
    }

    .date-select-second {
        width: 43%;
    }
}
&.ru-RU,  &.pt-PT{
    .date-select-second {
        width: 41%;
        line-height: 25px;
    }
}
&.de-DE{
    .date-select-second{
        line-height: 25px;
        height: 50px;
    }
}
&.fr-FR {
    .date-select-first {
        width: 58%;
        
    }

    .date-select-img {
        width: 10%;
    }

    .date-select-second {
        width: 55%;
    }
}
&.it-IT {
    .date-select-first {
        width: 55%;
    }

    .date-select-img {
        width: 10%;
    }

    .date-select-second {
        width: 55%;
    }
}
</style>
