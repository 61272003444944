import { unescape } from "lodash"
import moment from 'moment'

// 日期时间格式转换
export function formatDate(date, space = '') {
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()

    if (space === '') {
        return month + '月' + day + '日'
    } else {
        return month + space + day
    }
}

export function formatMinutes(date, space = '/') {
    const year = date.getFullYear()
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    return year + space + month + space + day + '   ' + hour + ':' + minutes
}

export function formatTime(date, space = '.') {
    const year = date.getFullYear()
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return year + space + month + space + day
}

export function formatDay(date) {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return day
}

export function formatMonth(date) {
    const month = date.getMonth() + 1
    const day = date.getDate()
    return month + '/' + day
}

// 数组深拷贝
export function clone(obj) {
    let copy

    // Handle the 3 simple types, and null or undefined
    if (obj === null || 'object' !== typeof obj) return obj

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date()
        copy.setTime(obj.getTime())
        return copy
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = []
        for (let i = 0, len = obj.length; i < len; i += 1) {
            copy[i] = clone(obj[i])
        }
        return copy
    }

    // Handle Object
    /* eslint-disable no-prototype-builtins */
    if (obj instanceof Object) {
        copy = {}
        for (let attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr])
        }
        return copy
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.')
}

// 获取文件url
export function getObjectURL(file) {
    let url = null
    // eslint-disable-next-line no-undefined
    if (window.createObjectURL !== undefined) {
        // basic
        url = window.createObjectURL(file)
        // eslint-disable-next-line no-undefined
    } else if (window.URL !== undefined) {
        // mozila(firefox)
        url = window.URL.createObjectURL(file)
        // eslint-disable-next-line no-undefined
    } else if (window.webkitURL !== undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file)
    }
    return url
}

// 手机号中间四位隐藏
export function hiddenPhone(val) {
    let str = null
    str = `${val.substr(0, 3)}****${val.substr(7)}`
    return str
}

/* x: 有效数字
 *  y: 保留位数
 */
export function toDecimal2(x, y) {
    let f = Math.round(x * 100) / 100
    let s = f.toString()
    let rs = s.indexOf('.')
    if (rs < 0) {
        rs = s.length
        s += '.'
    }
    while (s.length <= rs + y) {
        s += '0'
    }
    return s
}

// 实现将项目的图片转化成base64
export function convertImgToBase64(url, callback, outputFormat) {
    let canvas = document.createElement('CANVAS')
    let ctx = canvas.getContext('2d')
    let img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = function() {
        canvas.height = img.height
        canvas.width = img.width
        ctx.drawImage(img, 0, 0)
        let dataURL = canvas.toDataURL(outputFormat || 'image/png')
        callback.call(this, dataURL)
        canvas = null
    }
    img.src = url
}

/**
 * 数据精度处理
 *
 * @export
 * @param {Number} num 要处理的数据
 * @param {Number} digit 精度位数
 * @returns
 */
 export function _toDecimal(num, digit) {
    let f = Math.round(num * Math.pow(10, digit)) / Math.pow(10, digit)
    let s = f.toString()
    if (digit !== 0) {
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + digit) {
            s += '0'
        }
    } else {
        // 四舍五入
        s = Math.round(num)
    }
    return s
}

export function getCurrentParam() {
    const url = decodeURIComponent(window.location.search)
    var reg = new RegExp('(^|&)'+'deviceType'+'=([^&]*)(&|$)', 'i')
    var r = url.substring(1).match(reg)
    console.log(`获取浏览器参数`)
    if (r !== null) {
        return unescape(r[2])
    }

    return null
}

// 时间字符串转俄语格式
export function formatDateByRu(time, lang) {
    if(lang === 'ru-RU' || lang === 'ar-AR')  {
       return moment(time * 1000).format('DD/MM/YYYY HH:mm')
    }
    return moment(time * 1000).format('YYYY/MM/DD HH:mm')
}

// 单位翻译
export function _unitTranslation(unit, lang) {
    if(lang === 'ru-RU')  {
        if(unit === 'kg'){
            return 'кг'
        }else if(unit === 'kcal') {
            return 'ккал/д' 
        }else{
            return unit
        }
    }else if(lang === 'de-DE')  {
        if(unit === 'kg'){
            return 'kg'
        }else if(unit === 'kcal/d') {
            return 'kcal/Tag' 
        }else{
            return unit
        }
    }
    else if(lang === 'ar-AR')  {
        if(unit === 'kg'){
            return 'كجم'
        }else if(unit === 'lbs') {
            return 'رطل' 
        }else if(unit === 'kcal/d') {
            return 'كيلوكالوري/يوم' 
        }else if(unit === 'kg/㎡') {
            return 'كجم/م²' 
        }else{
            return unit
        }
    }
    else if(lang === 'el-GR')  {
        if(unit === 'kg'){
            return 'κιλά'
        }else if(unit === 'kcal/d') {
            return 'kcal/ημέρα' 
        }else{
            return unit
        }
    }
    else if(lang === 'it-IT')  {
        if(unit === 'kcal/d') {
            return 'kcal/g' 
        }else{
            return unit
        }
    }
    else{
        return unit
    }
}
    
	

// 转换时区字符串为分钟偏移
export function _parseTimezoneToMinutes(timezone) {
	// 检查传入的 timezone 是否为字符串
	if (typeof timezone !== "string") {
		console.error("Invalid timezone format:", timezone);
		return 0; // 默认返回 0 分钟偏移
	}
	
	// 检查是否是偏移量字符串（如 +05:30 或 -04:00）
	const match = timezone.match(/([+-])(\d{1,2}):?(\d{1,2})?/); // 支持 1-2 位的小时和分钟
	if (match) {
		const sign = match[1] === "+" ? 1 : -1;
		const hours = parseInt(match[2].padStart(2, "0"), 10) || 0; // 补齐不足 2 位
		const minutes = parseInt((match[3] || "0").padStart(2, "0"), 10) || 0; // 补齐不足 2 位
	
		return sign * (hours * 60 + minutes); // 返回分钟偏移
	}
	
	// 如果不是偏移量字符串，则尝试解析为 IANA 时区名称
	try {
			const now = new Date();
			const formatter = new Intl.DateTimeFormat("en-US", {
			timeZone: timezone,
			timeZoneName: "short",
		});
	
		const parts = formatter.formatToParts(now);
		const timeZoneOffset = parts.find((part) => part.type === "timeZoneName");
	
		// 匹配时区偏移部分（如 GMT+8、UTC-5）
		const offsetMatch = timeZoneOffset.value.match(/GMT([+-])(\d{1,2}):?(\d{1,2})?/);
		if (offsetMatch) {
			const sign = offsetMatch[1] === "+" ? 1 : -1;
			const hours = parseInt(offsetMatch[2], 10) || 0;
			const minutes = parseInt(offsetMatch[3] || "0", 10) || 0;
		
			return sign * (hours * 60 + minutes);
		}
	} catch (error) {
		console.error("Error parsing IANA timezone:", timezone, error);
	}
	
	// 如果解析失败，返回默认偏移 0
	return 0;
  }