/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 13:58:43
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-12-24 18:49:37
 */
import de from 'element-ui/lib/locale/lang/de.js'
import agrEn from './agr-en'
export default {
    // 登录页面
    login: {
        loginAndRegister: 'Anmelden / Einloggen',
        registerAndLogin: 'Einloggen / Anmelden',
        userLogin: 'Anmelden',
        title: 'Bitte beachten Sie, dass Mobil- und E-Mail-Konten unabhängig voneinander sind.',
        mobileLogin: 'Telefon',
        emailLogin: 'E-Mail',
        email: 'E-Mail',
        loginBtn: 'Anmelden',
        autoLogin: 'Angemeldet bleiben',
        retransmission: 's',
        formList: [
            {
                label: 'Geben Sie Ihre Telefonnummer ein',
                placeholder: 'Senden',
            },
            {
                label: 'Verifizier ungscode',
                placeholder: 'Code eingeben',
            },
            {
                label: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
            },
        ],
        btn: {
            submit: 'Konto erstellen',
            title: 'Um genaue Daten zu erhalten, ist es wichtig, Größe, Geschlecht und Alter korrekt anzugeben.',
        },
        rule: {
            phone: {
                requiredMsg: 'Ungültiges Telefonnummernformat',
                patternMsg: 'Telefon nicht registriert, bitte Konto erstellen.',
            },
            authCode: {
                requiredMsg: 'Falscher Verifizierungscode, bitte erneut eingeben',
            },
            email: {
                requiredMsg: 'Falsches E-Mail-Format',
                patternMsg: 'E-Mail nicht registriert, bitte Konto erstellen.',
            },
        },
        codeSuccess: 'Der Bestätigungscode wurde gesendet, bitte überprüfen Sie.',
        success: 'Senden erfolgreich',
        error: 'Senden fehlgeschlagen',
        loginSuccess: 'Anmeldung erfolgreich',
    },
    // 注册页面
    register: {
        mobileRegister: 'Telefon',
        emailRegister: 'E-Mail',
        enroll: 'Handynummer bereits registriert',
        enrollEmail: 'E-Mail ist registriert',
        search: 'Suche',
        title: 'Bitte beachten Sie, dass das per E-Mail und Telefonnummer eingerichtete Konto unabhängig voneinander ist.',
        needRead: {
            agree: 'Ich stimme zu',
            privacy: '(Datenschutzrichtlinie)',
        },
        register: 'Anmelden',
        check: 'Bitte "Benutzerdatenschutz" auswählen',
        newUser: 'Anmelden',
        form: {
            labelList: ['Name', '* Geschlecht', '* Größe', '* Alter'],
        },
        placeholder: ['Bitte geben Sie Ihren Namen ein', 'Größe eingeben', 'Bitte geben Sie Ihr Alter ein'],
        unit: ['Metrisch', 'Imperial', ''],
        sexOptions: {
            male: 'Männlich',
            female: 'Weiblich',
        },
        btn: 'Absenden',
        rules: {
            metricheight: [`Der Größenbereich liegt zwischen 2'3" - 6'7", bitte geben Sie ihn erneut ein.`],
            heightMsg: ['Der Größenbereich liegt zwischen 70 - 200cm, bitte geben Sie ihn erneut ein.'],
            ageMsg: ['Der Altersbereich liegt zwischen 10 - 99, bitte geben Sie ihn erneut ein.'],
            agePointMsg: [
                'Nicht ganzzahliger Wert eingegeben: Das Alter kann nur eine Ganzzahl sein, bitte geben Sie das korrekte Alter ein',
            ],
        },
        success: 'Registrierung erfolgreich',
        userMessage: 'Geben Sie den Benutzernamen ein',
        verify: 'Geben Sie den 4-stelligen Verifizierungscode ein',
        errorMsg: 'Das Größenformat unterstützt keine Dezimalzahlen, bitte erneut eingeben',
    },
    // 模型合成
    model: {
        loading: 'Laden...Bitte warten Sie einen Moment',
        modelLoading: '3D-Modell laden',
        tabPane: [
            {
                title: 'Deep Real Sense Technologie',
                msg: 'Sammelt genau lokale Details des Körpers durch 3D-Scannen, gewährleistet die millimetergenaue Genauigkeit der Umfangsmessung und zeigt deutlich die Körperveränderungen nach dem Training.',
            },
            {
                title: 'BDA Körperzusammensetzungs-Algorithmus',
                msg: 'Visbody verwendet das fortschrittlichste System zur Messung der Körperzusammensetzung - den BDA-Algorithmus zur Bestimmung der Körperzusammensetzung. Diese auf dem Körpervolumen basierende Berechnungsmethode wird die Gesundheitsrisiken, die durch Fettleibigkeit verursacht werden, genauer beurteilen.',
            },
            {
                title: 'Faktoren, die Fehler verursacht haben',
                msg: 'Lockere Kleidung oder einige Dekorationen am/im Körper verursachen Fehler, bitte tragen Sie enge Kleidung oder reduzieren Sie die Menge an Kleidung auf Ihrem Körper, wenn Sie die genauesten Daten erhalten möchten.',
            },
            {
                title: 'Erhalten Sie Ihren Bericht',
                msg: 'Es wird empfohlen, die URL der Webseite zu den Favoriten hinzuzufügen, um den Bericht in der Zukunft leicht einsehen zu können. Sie können diesen Bericht auch an Ihr Postfach senden, um ihn später zu speichern.',
            },
        ],
        collect: [
            'Bitte fügen Sie diese Seite zu Ihren Favoriten hinzu, damit Sie den Bericht in Zukunft leichter einsehen können.',
            'Einfacher Zugang zum Bericht in der Zukunft.',
        ],
        know: 'Ich habe es verstanden',
        reminder: [
            'Freundliche Erinnerung',
            'Ihr 3D-Avatar-Modell wurde erstellt, wischen Sie nach links und rechts, um das Modell zu drehen.',
            'Ihr Bericht wurde erstellt und kann durch Klicken auf die Schaltfläche "Bericht anzeigen" eingesehen werden.',
            'Ihre Körperbewertung war nicht erfolgreich, bitte überprüfen Sie den Körperzusammensetzungsbericht.',
            'Ihre Körperzusammensetzung war nicht erfolgreich, bitte überprüfen Sie den Haltungsbewertungsbericht.',
            'Ihre Messung war nicht erfolgreich, bitte gehen Sie zum Gerät und testen Sie erneut.',
            'Die Messung der Körperzusammensetzung war nicht erfolgreich, bitte gehen Sie zum Gerät und testen Sie erneut.',
            'Die Haltungsbewertung war nicht erfolgreich, bitte gehen Sie zum Gerät und testen Sie erneut.',
        ],
        btn: {
            viewReport: 'Bericht anzeigen',
            bodyReport: 'KZ anzeigen',
            postureReport: 'Haltungsbewertungsbericht anzeigen',
            lastReport: 'Letzten Bericht anzeigen',
            loading: 'Laden',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'Bericht herunterladen',
            send: 'Bericht senden',
            report: 'Bericht',
        },
        reportSuccess: 'Erfolgreich!',
        tabPane: {
            labelList: ['Messzeit', 'Artikel', 'Körperzusammensetzung', 'Haltungsbewertung', 'Schulterfunktion'],
        },
        sendEmail: 'E-Mail:',
        requiredMsg: 'Geben Sie Ihre E-Mail-Adresse ein',
        patternMsg: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        send: 'Senden',
        download: 'Bericht herunterladen',
        sendReport: 'Bericht senden',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'Geschlecht erfolgreich geändert',
            height: 'Größe erfolgreich geändert',
            age: 'Alter erfolgreich geändert',
        },
        logOut: 'Abmelden',
        personal: 'Persönliches Zentrum',
        cut: 'Einheiten/Sprachen ändern',
        changeSort: 'Produktberichtwechsel',
        describe: '* Nach dem Wechsel des Produktmodells werden alle Messberichte unter diesem Modell angezeigt.',
        describeBtn:
            'Nach dem Wechsel des Produktmodells werden Sie zum neuesten Messbericht für dieses Modell weitergeleitet.',
        descReport: 'Produktbericht erfolgreich umgestellt',
        unit: ['Metrisch (kg, cm)', 'Imperial (ft, in, lb)'],
        read: {
            reading: 'Lesen',
            privacy: '(Datenschutzrichtlinie)',
        },
        setting: 'Einstellung',
        form: {
            labelList: ['Telefon', 'Einheiten', 'Sprachen', 'Name', 'Geschlecht', 'Größe', 'Alter'],
        },
        placeholder: ['Ändern Sie Ihren Benutzernamen', 'Neues Geschlecht', 'Größe aktualisiert', 'Alter aktualisiert'],
        btn: {
            cancelBtn: 'Abbrechen',
            accomplishBtn: 'Abschließen',
            confirmBtn: 'Bestätigen',
        },
        dialog: [
            'Geschlecht ist eine wichtige Grundlage für die Messung der Körperzusammensetzung. Die nach der Änderung gemessenen Daten werden von den vorherigen Daten abweichen. Bitte bestätigen Sie, ob Sie dies ändern möchten?',
            'Größe ist eine wichtige Grundlage für die Messung der Körperzusammensetzung. Die nach der Änderung gemessenen Daten werden von den vorherigen Daten abweichen. Bitte bestätigen Sie, ob Sie dies ändern möchten?',
            'Alter ist eine wichtige Grundlage für die Messung der Körperzusammensetzung. Die nach der Änderung gemessenen Daten werden von den vorherigen Daten abweichen. Bitte bestätigen Sie, ob Sie dies ändern möchten?',
        ],
        rules: {
            metricheight: [`Bitte geben Sie den korrekten Größenbereich ein (2'3" bis 6'7").`],
            heightMsg: ['Bitte geben Sie die Größe innerhalb von 70cm - 200cm ein'],
            ageMsg: [
                'Bitte geben Sie das Alter innerhalb von 10 - 99 Jahren ein.',
                'Eingegebener Wert ist keine Ganzzahl, das Alter kann nur eine Ganzzahl sein, bitte geben Sie das korrekte Alter ein',
            ],
        },
        heightMsg: 'Bitte wählen Sie die Größe erneut',
        ageMsg: 'Bitte wählen Sie das Alter erneut',
        ModelBinding: 'Es scheint, dass jemand den Code bereits gescannt hat, bitte messen Sie erneut!',
        ReportEmpty: {
            title: 'Sie haben noch keinen Bericht',
            desc: 'Kommen Sie zum Visbody 3D-Körperscanner und testen Sie erneut!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'Es scheint, dass jemand den Code bereits gescannt hat, bitte messen Sie erneut!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: 'Sie haben noch keinen Bericht',
        desc: 'Kommen Sie zum Visbody 3D-Körperscanner und testen Sie erneut!',
        descS30: 'Kommen Sie zum Visbody S30 3D-Körperscanner und testen Sie erneut!',
        descM30: 'Kommen Sie zum Visbody M30 3D-Körperscanner und testen Sie erneut!',
    },
    // 用户信息
    userInfo: {
        height: 'Größe:',
        age: 'Alter:',
        unit: '',
        weight: 'Gewicht',
    },
    // 体成分
    mass: {
        title: 'Übersicht über die Bewertung der Körperzusammensetzung',
        titles: 'Körperzusammensetzung',
        springFrame: '',
        massFrame: 'Keine Messdaten für den Tag',
        contrast: 'Wählen Sie einen Bericht zum Vergleichen',
        contrastReport: 'Wählen Sie einen Bericht zum Vergleichen',
        noRecord: 'Keine Aufzeichnungen',
        score: 'Punktzahl',
        status: 'Ihr aktueller Zustand der Körperzusammensetzung',
        WT: 'Gewicht',
        PBF: 'Körperfettanteil',
        endSymbol: '。',
        // 测量相关
        measure: {
            value: 'Werte',
            standardRange: 'Standardbereich',
            comparedWithLastLime: 'Vergleich',
            comparedNet: 'Im Vergleich zur letzten Bewertung',
            current: '本次测量分数',
            WT: 'Gewicht',
            FFM: 'Fettfreie Körpermasse',
            BFM: 'Körperfettmasse',
            LM: 'Muskelmasse',
            TBW: 'Wasseranteil im Körper',
            SM: 'SMM (Skelettmuskelmasse)',
            PROTEIN: 'Eiweiß',
            TM: 'Anorganische Salze',
            BMR: 'Grundumsatz',
            WHR: 'WHR (Taille-Hüft-Verhältnis)',
            BMI: 'BMI',
            PBF: 'KFA (Körperfettanteil)',
            VFG: 'Viszeraler Fettgehalt',
            unit: 'kg',
            BMRUnit: 'kcal/d',
        },
        // 定义解读
        explain: [
            {
                title: 'Gewicht',
                msg: 'Das Gewicht ist die Summe aus Körperwasser, Protein und anorganischem Salz.',
            },
            {
                title: 'Fettfreie Körpermasse',
                msg: 'Die Magermasse ist das Gesamtkörpergewicht ohne Fett.',
            },
            {
                title: 'Körperfettmasse',
                msg: 'Die Körperfettmasse ist die Summe aus subkutanem Fett und viszeralem Fett.',
            },
            {
                title: 'Muskelmasse',
                msg: 'Die Magermasse umfasst Skelettmuskulatur, glatte Muskulatur und Herzmuskulatur.',
            },
            {
                title: 'Wasseranteil im Körper',
                msg: 'Der größte Teil des menschlichen Körpers besteht aus Wasser, das 50%-70% des Körpergewichts ausmacht. Das Körperwasser befindet sich hauptsächlich in menschlichen Zellen und Körperflüssigkeiten, von denen der größte Teil in Muskelzellen ist.',
            },
            {
                title: 'SMM',
                msg: 'Die Skelettmuskelmasse, auch Streifenmuskulatur genannt, ist eine Art von Muskel, der an den Knochen befestigt ist. Diese Daten enthalten die Menge an Skelettmuskulatur.',
            },
            {
                title: 'Eiweiß',
                msg: 'Eiweiß ist eine wesentliche Substanz, die in allen Zellen des menschlichen Körpers vorhanden ist und die Hauptkomponente der Muskelmasse darstellt.',
            },
            {
                title: 'Anorganische Salze',
                msg: 'Der menschliche Körper besteht aus organischer Materie, anorganischer Materie und Wasser. Die hier genannte anorganische Materie sind anorganische Salze, die 5% des Körpergewichts ausmachen.',
            },
            {
                title: 'Grundumsatz',
                msg: 'Der Grundumsatz ist die Gesamtenergie, die an einem Tag verbraucht wird, wenn der Körper in Ruhe ist, nicht beeinflusst durch Bewegung, physische Objekte, Nervosität, externe Temperaturänderungen usw.',
            },
            {
                title: 'WHR (Taille-Hüft-Verhältnis)',
                msg: 'Das Verhältnis von Taillen- zu Hüftumfang ist ein wichtiger Indikator zur Bestimmung der zentralen Fettleibigkeit.',
            },
            {
                title: 'BMI',
                msg: 'Der BMI wird hauptsächlich zur Beurteilung des Erscheinungsbilds von Fettleibigkeit verwendet und ist ein gängiger Standard zur Messung des Körperfettanteils.',
            },
            {
                title: 'KFA (Körperfettanteil)',
                msg: 'Der KFA ist eine Messung der Körperzusammensetzung, die angibt, wie viel des Körpergewichts Fett ist.',
            },
            {
                title: 'Viszeraler Fettgehalt',
                msg: 'Viszerales Fett ist ein wichtiger Indikator zur Beurteilung der versteckten Fettleibigkeit.',
            },
            {
                title: 'Intrazelluläres Wasser',
                msg: 'ICW: Es handelt sich um die Körperflüssigkeit, die in den Körperzellen enthalten ist und die Grundkomponente des Protoplasmas ist.',
            },
            {
                title: 'Extrazelluläres Wasser',
                msg: 'ECW: Bezieht sich normalerweise auf extrazelluläre Körperflüssigkeiten, einschließlich Plasma und Interzellularflüssigkeit zwischen Blutgefäßen und Gewebezellen.',
            },
            {
                title: 'Stoffwechselalter',
                msg: 'Das Stoffwechselalter bezieht sich auf die Leistung des Körpers und seiner mit der Gesundheit verbundenen Stoffwechselfunktionen.',
            },
        ],
        // 节段
        segment: {
            fat: 'Segmentale Fettanalyse',
            muscle: 'Segmentaler Muskel',
            right: 'Rechts',
            left: 'Links',
            standard: 'Normal',
            lowStandard: 'Unter',
            superStandard: 'Über',
            peel: '(Tara)',
        },
    },
    // 体态
    shape: {
        title: 'Übersicht über die Körperbewertung',
        titles: 'Haltung',
        status: 'Ihr aktueller körperlicher Zustand',
        suggest: 'Vorschlag',
        possibility: ', es besteht die Möglichkeit von',
        models: {
            front: 'Vorne',
            left: 'Linke Seite',
            right: 'Rechte Seite',
            top: 'Rücken',
            low: 'Unter',
            high: 'Über',
            normal: 'Normal',
        },
        item: {
            deviate: 'Werte',
            result: ' Beschreibung',
            normal: 'Normal',
			keAbnormal: 'Verdacht auf Anomalie',
            abnormal: 'Anormal',
            head: 'Kopfhaltung nach vorn',
            headSlant: 'Kopfneigung',
            roundShoulderLeft: 'Schulter noch vorne gekippt (linke Seite)',
            roundShoulderRight: 'Schulter nach vorne gekippt (rechte Seite)',
            highLowShoudler: 'Schultern ungleich',
            pelvis: 'Vorwärts/Rückwärts Verschiebung des Beckens',
            leftKneeCheck: 'Beurteilung linkes Knie',
            rightKneeCheck: 'Beurteilung rechtes Knie',
            leg: 'Beintyp',
            leftLeg: 'Linkes Bein:',
            rightLeg: 'Rechtes Bein:',
        },
        // 定义解读
        explain: [
            {
                title: 'Vorwärts gerichtete Kopfhaltung',
                msg: 'Der Winkel zwischen dem Ohrpunkt auf der linken Seite und der Linie, die das Zentrum des Halses und die Mittellinie auf der Seite verbindet',
            },
            {
                title: 'Kopfneigung',
                msg: 'Der Winkel zwischen dem Mittelpunkt des vorderen Kopfes und dem Zentrum des Halses und der Mittellinie der Vorderseite',
            },
            {
                title: 'Rundschulternhaltung (linke Seite)',
                msg: 'Der Winkel zwischen der Linie, die den höchsten Punkt auf der linken Rückenseite verbindet, und der Tangente der Schulter',
            },
            {
                title: 'Rundschulternhaltung (rechte Seite)',
                msg: 'Der Winkel zwischen der Linie, die den höchsten Punkt auf der rechten Rückenseite verbindet, und der Tangente der Schulter',
            },
            {
                title: 'Ungleiche Schultern',
                msg: 'Der vertikale Abstand zwischen den linken und rechten Schulterpunkten auf dem Rücken',
            },
            {
                title: 'Becken vorwärts/Becken rückwärts Verlagerung',
                msg: 'Der eingeschlossene Winkel der Linie, die den Mittelpunkt des Halses, den Hüftknochenpunkt und den Knöchelpunkt auf der linken Seite verbindet',
            },
            {
                title: 'Linke Kniebewertung',
                msg: 'Der eingeschlossene Winkel der Dreipunktelinie, die den Hüftknochenpunkt, das Kniegelenk und den Knöchelpunkt auf der linken Seite verbindet',
            },
            {
                title: 'Rechte Kniebewertung',
                msg: 'Der eingeschlossene Winkel der Dreipunktelinie, die den Hüftknochenpunkt, das Kniegelenk und den Knöchelpunkt auf der rechten Seite verbindet',
            },
            {
                title: 'Beinform',
                msg: 'Der eingeschlossene Winkel der Linie, die den Hüftknochenpunkt, das Kniegelenk und den Knöchelpunkt auf der Vorderseite der linken und rechten Beine verbindet',
            },
        ],
        exception: [
            {
                title: 'Zur linken Seite geneigt',
                abnormal: 'zur linken Seite geneigt',
            },
            {
                title: 'Zur rechten Seite geneigt',
                abnormal: 'zur rechten Seite geneigt',
            },
            {
                title: 'Links hoch',
                abnormal: 'links hoch',
            },
            {
                title: 'Rechts hoch',
                abnormal: 'rechts hoch',
            },
            {
                title: 'Rückwärtige Beckenkippung',
                abnormal: 'rückwärtige Beckenkippung',
            },
            {
                title: 'Vorwärtige Beckenkippung',
                abnormal: 'vorwärtige Beckenkippung',
            },
            {
                title: 'Hyperextension des linken Knies',
                abnormal: 'Hyperextension des linken Knies',
            },
            {
                title: 'Linkes Knie Vorwärtsbiegung',
                abnormal: 'linkes Knie Vorwärtsbiegung',
            },
            {
                title: 'Hyperextension des rechten Knies',
                abnormal: 'Hyperextension des rechten Knies',
            },
            {
                title: 'Rechtes Knie Vorwärtsbiegung',
                abnormal: 'rechtes Knie Vorwärtsbiegung',
            },
            {
                title: 'K-förmige Beine',
                abnormal: 'k-förmige Beine',
            },
            {
                title: 'D-förmige Beine',
                abnormal: 'd-förmige Beine',
            },
            {
                title: 'X-förmige Beine',
                abnormal: 'x-förmige Beine',
            },
            {
                title: 'O-förmige Beine',
                abnormal: 'o-förmige Beine',
            },
        ],
    },
    newshape: {
        explain: [
            {
                msg: 'Vertikaler Abstand zwischen den Ohren und der Körperschwerlinie',
            },
            {
                msg: 'Winkel zwischen der Verbindungslinie beider Ohren und der Horizontalebene',
            },
            {
                msg: 'Vertikaler Abstand zwischen dem linken Acromion und der Schwerlinie',
            },
            {
                msg: 'Vertikaler Abstand zwischen dem rechten Acromion und der Schwerlinie',
            },
            {
                msg: 'Differenz der Verbindungslinien zwischen Halsansatz und Acromion beider Seiten',
            },
            {
                msg: 'Vertikaler Abstand zwischen dem Hüftgelenk und der Körperschwerlinie',
            },
            {
                msg: 'Winkel zwischen Sprunggelenk, 1 cm unterhalb des linken Kniegelenks und Hüftgelenk in der Sagittalebene',
            },
            {
                msg: 'Winkel zwischen Sprunggelenk, 1 cm unterhalb des rechten Kniegelenks und Hüftgelenk in der Sagittalebene',
            },
            {
                msg: 'Winkel zwischen bilateralen Hüft-, Knie- und Sprunggelenken in der Frontalebene',
            },
        ],
        item: {
            pelvis: 'Anteriore Beckenkippung',
        },
    },
    // 围度信息
    girth: {
        title: 'Umfänge',
        present: '(Gegenwärtig)',
        noHistory: 'Keine Daten',
        tpl: [
            {
                title: 'Halsumfang',
                key: 'neckGirth',
            },
            {
                title: 'Linker Oberarm',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'Rechter Oberarm',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'Oberweite',
                key: 'bustGirth',
            },
            {
                title: 'Hohe Taille',
                key: 'waistGirth',
            },
            {
                title: 'Mittlere Taille',
                key: 'midWaistGirth',
            },
            {
                title: 'Hüftlinie',
                key: 'hipGirth',
            },
            {
                title: 'Linker Oberschenkel',
                key: 'leftThighGirth',
            },
            {
                title: 'Linker Oberschenkel Mitte',
                key: 'leftMidThighGirth',
            },
            {
                title: 'Minimaler Umfang des linken Oberschenkels',
                key: 'leftMinThighGirth',
            },
            {
                title: 'Rechter Oberschenkelumfang',
                key: 'rightThighGirth',
            },
            {
                title: 'Rechter Oberschenkel Mitte',
                key: 'rightMidThighGirth',
            },
            {
                title: 'Minimaler Umfang des rechten Oberschenkels',
                key: 'rightMinThighGirth',
            },
            {
                title: 'Linker Wadenumfang',
                key: 'leftCalfGirth',
            },
            {
                title: 'Rechter Wadenumfang',
                key: 'rightCalfGirth',
            },
            {
                title: 'Niedrige Taille',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'Schulterfunktion',
        model: {
            val: 'Werte',
            scope: 'Maximale Aktivitätsposition',
            diff: 'Netto',
        },
        normal: 'Notable abnormalities not detected.',
        normalAll: 'Die Funktion der Schulter zeigt vorerst keine offensichtlichen Abnormalitäten.',
        conclusion: 'Fazit',
        analyse: 'Analyse:',
        suggest: 'Vorschlag:',
        cause: 'Für Details wenden Sie sich bitte an Fachexperten.',
    },
    reportTips: {
        title: 'Bitte kontaktieren Sie den Administrator, wenn Sie den Bericht löschen möchten.',
        btnMsg: 'OK',
        alaryTips: 'Dieser Bericht wurde vom Administrator gelöscht. Es wird zum nächsten Bericht gewechselt!',
    },
    saveQr: {
        title: 'Visbody 3D Body Scanner Bericht',
        btnMsg: 'Drücken Sie lange auf das Bild, um es auf Ihrem Handy zu speichern.',
        tips: 'Wenn Sie feststellen, dass Sie nicht speichern können, gehen Sie bitte zu [Einstellungen] und öffnen Sie die entsprechenden Berechtigungen.',
    },
    share: {
        title: 'Bitte kopieren Sie den untenstehenden Link und teilen Sie ihn.',
        btnMsg: 'Kopieren',
    },
    ...de,
    ...agrEn,
}
